import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);



let state = {
  user_token: localStorage.getItem('user_token'),
  user: JSON.parse(localStorage.getItem('user')),
  language: null,
  languages: [
    {
      short: "mn",
      long: "Мон"
    },
    {
      short: "en",
      long: "Eng"
    },
  ],
  anketModal:false,
  anket_id:0,
  box: localStorage.getItem('box') ? JSON.parse(localStorage.getItem('box')) : [],
  barigad_ajiliin_bairni_zar: localStorage.getItem('barigad_ajiliin_bairni_zar') ? JSON.parse(localStorage.getItem('barigad_ajiliin_bairni_zar')) : [],
  baiguullaga_ajiliin_zar: localStorage.getItem('baiguullaga_ajiliin_zar') ? JSON.parse(localStorage.getItem('baiguullaga_ajiliin_zar')) : [],
  brigad_ajiliin_zar: localStorage.getItem('brigad_ajiliin_zar') ? JSON.parse(localStorage.getItem('brigad_ajiliin_zar')) : [],
  baiguullaga: localStorage.getItem('baiguullaga') ? JSON.parse(localStorage.getItem('baiguullaga')) : [],
  brigad: localStorage.getItem('brigad') ? JSON.parse(localStorage.getItem('brigad')) : [],
};

state.language = state.languages[0];
// baiguullaga ajiliin bairnii zar
function updateLocalStorage(box){
  localStorage.setItem('box', JSON.stringify(box))
}
// brigad ajiliin bairnii zar
function updateLocalStorage1(barigad_ajiliin_bairni_zar){
  localStorage.setItem('barigad_ajiliin_bairni_zar', JSON.stringify(barigad_ajiliin_bairni_zar))
}
// baiguullaga ajiliin zar
function updateLocalStorage2(baiguullaga_ajiliin_zar){
  localStorage.setItem('baiguullaga_ajiliin_zar', JSON.stringify(baiguullaga_ajiliin_zar))
}
// brigad ajiliin zar
function updateLocalStorage3(brigad_ajiliin_zar){
  localStorage.setItem('brigad_ajiliin_zar', JSON.stringify(brigad_ajiliin_zar))
}
// baigauullaga
function updateLocalStorageBaiguullaga(baiguullaga){
  localStorage.setItem('baiguullaga', JSON.stringify(baiguullaga))
}
// brigad
function updateLocalStorageBrigad(brigad){
  localStorage.setItem('brigad', JSON.stringify(brigad))
}
const store = new Vuex.Store({
  state:state,
  mutations: {
    setLanguage(state, lang) {
      state.language = lang;
    },
    setUser(state, user) {
      state.user = user;
      window.init.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    setUserToken(state, token) {
      state.user_token = token;
      window.axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
      localStorage.setItem('user_token', token);
    },
    setStorageValues(state) {
      state.user = JSON.parse(localStorage.getItem('user'));
      state.user_token = localStorage.getItem('user_token');
    },
    setAnketID(state, value){
      state.anket_id = value
    },

    // baiguullaga ajiliin bairnii zar
    addToZar(state, zar) {
      let itemIndex = state.box.findIndex(i => i.zar_id == zar.zar_id);
      if(itemIndex == -1){
        state.box.push(zar)
      }
      updateLocalStorage(state.box)
    },
    removeFromZar(state, zar_id) {
      let deleteIndex = state.box.findIndex(item=>item.zar_id == zar_id);
      if(deleteIndex >=deleteIndex){
        state.box.splice(deleteIndex, 1);
      }
      updateLocalStorage(state.box)
    },

    // brigad ajiliin bairnii zar
    addToBrigadAjiliinBairZar(state, zar) {
      let itemIndex = state.barigad_ajiliin_bairni_zar.findIndex(i => i.zar_id == zar.zar_id);
      if(itemIndex == -1){
        state.barigad_ajiliin_bairni_zar.push(zar)
      }
      updateLocalStorage1(state.barigad_ajiliin_bairni_zar)
    },
    removeBrigadAjiliinBairZar(state, zar_id) {
      let deleteIndex = state.barigad_ajiliin_bairni_zar.findIndex(item=>item.zar_id == zar_id);
      if(deleteIndex >=deleteIndex){
        state.barigad_ajiliin_bairni_zar.splice(deleteIndex, 1);
      }
      updateLocalStorage1(state.barigad_ajiliin_bairni_zar)
    },

    // baiguullaga ajiliin zar
    addToBaiguullagaAjilZar(state, zar) {
      let itemIndex = state.baiguullaga_ajiliin_zar.findIndex(i => i.zar_id == zar.zar_id);
      if(itemIndex == -1){
        state.baiguullaga_ajiliin_zar.push(zar)
      }
      updateLocalStorage2(state.baiguullaga_ajiliin_zar)
    },
    removeBaiguullagaAjilZar(state, zar_id) {
      let deleteIndex = state.baiguullaga_ajiliin_zar.findIndex(item=>item.zar_id == zar_id);
      if(deleteIndex >=deleteIndex){
        state.baiguullaga_ajiliin_zar.splice(deleteIndex, 1);
      }
      updateLocalStorage2(state.baiguullaga_ajiliin_zar)
    },

    // brigad ajiliin zar
    addToBrigadAjilZar(state, zar) {
      let itemIndex = state.brigad_ajiliin_zar.findIndex(i => i.zar_id == zar.zar_id);
      if(itemIndex == -1){
        state.brigad_ajiliin_zar.push(zar)
      }
      updateLocalStorage3(state.brigad_ajiliin_zar)
    },
    removeBrigadAjilZar(state, zar_id) {
      let deleteIndex = state.brigad_ajiliin_zar.findIndex(item=>item.zar_id == zar_id);
      if(deleteIndex >=deleteIndex){
        state.brigad_ajiliin_zar.splice(deleteIndex, 1);
      }
      updateLocalStorage3(state.brigad_ajiliin_zar)
    },

    // baiguullaga
    addToBaiguullaga(state, company) {
      let itemIndex = state.baiguullaga.findIndex(i => i.baiguullaga_id == company.id);
      if(itemIndex == -1){
        state.baiguullaga.push(company)
      }
      updateLocalStorageBaiguullaga(state.baiguullaga)
    },
    removeFromBaiguullaga(state, id) {
      let deleteIndex = state.baiguullaga.findIndex(item=>item.baiguullaga_id == id);
      if(deleteIndex >=deleteIndex){
        state.baiguullaga.splice(deleteIndex, 1);
      }
      updateLocalStorageBaiguullaga(state.baiguullaga)
    },

    // brigad
    addToBrigad(state, bri) {
      let itemIndex = state.brigad.findIndex(i => i.brigad_id == bri.id);
      if(itemIndex == -1){
        state.brigad.push(bri)
      }
      updateLocalStorageBrigad(state.brigad)
    },
    removeFromBrigad(state, id) {
      let deleteIndex = state.brigad.findIndex(item=>item.brigad_id == id);
      if(deleteIndex >=deleteIndex){
        state.brigad.splice(deleteIndex, 1);
      }
      updateLocalStorageBrigad(state.brigad)
    },
  },
  getters:{
    anket_id(){
      return state.anket_id
    },
    languages(){
      return state.languages
    },
    language(){
      return state.language
    },
    user() {
      return state.user
    },
    userToken() {
      return state.user_token
    },
    zarItems: (state) => state.box,
    b_a_b_zar: (state) => state.barigad_ajiliin_bairni_zar,
    b_a_zar: (state) => state.baiguullaga_ajiliin_zar,
    br_a_zar: (state) => state.brigad_ajiliin_zar,
    baiguullaga: (state) => state.baiguullaga,
    brigad: (state) => state.brigad,
  },
  actions: {},
  modules: {},
});

export default store;