<template>
  <div id="main">
    <app-header></app-header>
    <router-view :key="$route.fullPath"></router-view>
    <app-footer></app-footer>
    <a class="scrollToTop" href="javascript:;" onclick="window.scrollTo(0, 0)"><i class="ivu-icon ivu-icon-ios-arrow-up"></i></a>
  </div>
</template>
<script>
import AppHeader from "./components/header";
import AppFooter from "./components/footer";
import { mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  computed: {
    ...mapGetters([
      'user',
      'userToken'
    ])
  },
  created() {
    if(this.userToken != null && this.userToken != 'null' && this.userToken != ''){
      window.init.user = this.user;
      window.axios.defaults.headers.common['Authorization'] = 'Bearer '+this.userToken;
    }
  }
}
</script>
