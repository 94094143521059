<template>
  <div>
    <div class="footer_container" id="footer_container">
      <div class="container">
        <div class="row">
          <div class=" col-md-4 col-sm-12 ">
            <div class="footer_titles">{{ 'holboo_barih' | translate }}</div>
            <div class="footer_socials">
              <a :href="contact.facebook" target="_blank">
                <i class="ivu-icon ivu-icon-logo-facebook"></i>
              </a>
              <a :href="contact.twitter" target="_blank">
                <i class="ivu-icon ivu-icon-logo-twitter"></i>
              </a>
              <a :href="contact.linkedin" target="_blank">
                <i class="ivu-icon ivu-icon-logo-linkedin"></i>
              </a>
            </div>
            <div class="footer_contact">
              <div class="row">
                <div class="col-sm-1">
                  <Icon type="md-map"/>
                </div>
                <div class="col-sm-11">
                  <span v-if="language.short == 'mn'" class="spanText">{{ contact.hayg_mn }}</span>
                  <span v-else class="spanText">{{ contact.hayg_en }}</span>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-12">
                  <p>
                    <Icon type="md-call"/> &nbsp;&nbsp; {{ contact.phone_one }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p>
                    <Icon type="md-call"/> &nbsp;&nbsp; {{ contact.phone_two }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p>
                    <Icon type="md-mail"/> &nbsp;&nbsp; {{ contact.mail }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p>
                    <Icon type="md-globe"/> &nbsp;&nbsp;{{ contact.tuhai }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4  col-sm-12">
            <div class="footer_titles">{{ 'sistemiin_hundalt' | translate }}</div>
            <div class="systemiin_xandalt_cont">
              <div class="row">

                <div class="col-sm-12">
                  <img src="../assets/pic/ajiltan_logo.svg" class="float-left"
                       style="padding-left: 4px" alt="">
                  <p class="float-left"> &nbsp;{{ 'today' | translate }}: {{ga.today | number}} </p>
                  <p class="float-right"></p>
                </div>

                <div class="col-sm-12">
                  <img src="../assets/pic/ajiltan_logo.svg" class="float-left"
                       style="padding-left: 4px" alt="">
                  <p class="float-left"> &nbsp; {{ 'week' | translate }}: {{ga.week | number}}</p>
                  <p class="float-right"></p>
                </div>

                <div class="col-sm-12">
                  <img src="../assets/pic/ajiltan_logo.svg" class="float-left"
                       style="padding-left: 4px" alt="">
                  <p class="float-left"> &nbsp; {{ 'month' | translate }}: {{ga.month | number}}</p>
                  <p class="float-right"></p>
                </div>

                <div class="col-sm-12">
                  <img src="../assets/pic/ajiltan_logo.svg" class="float-left"
                       style="padding-left: 4px" alt="">
                  <p class="float-left"> &nbsp; {{ 'year' | translate }}: {{ga.year | number}}</p>
                  <p class="float-right"></p>
                </div>

                <div class="col-sm-12">
                  <img src="../assets/pic/ajiltans_logo.svg" class="float-left ajiltans_logo" alt="">
                  <p class="float-left"> &nbsp; {{ 'total' | translate }}: {{ga.allTime | number}} </p>
                  <p class="float-right"></p>
                </div>
                <div class="col-sm-12 undertxt">
                  <p>
                    {{ 'online' | translate }}
                    <br>
                    {{ 'your_ip' | translate }} {{ga.IPAddress}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--BRIEF INTRODUCTION OF THE NATIONAL CONSTRUCTION ASSOCIATION OF MONGOLIA-->
          <div class="col-md-4 col-sm-12">
            <div class="sanal_huselt">
              <div class="footer_titles">{{ 'sanaL_hus' | translate }}</div>
              <div class="form">
                <Form ref="formValidate" :model="formValidate" :rules="ruleValidate">
                  <FormItem prop="name">
                    <Input v-model="formValidate.name" :placeholder="'name' | translate"></Input>
                  </FormItem>
                  <FormItem prop="mail">
                    <Input v-model="formValidate.mail"
                           :placeholder="'tsahim_hayag' | translate"></Input>
                  </FormItem>
                  <FormItem prop="desc">
                    <Input v-model="formValidate.desc" type="textarea"
                           :autosize="{minRows: 4,maxRows: 4}"
                           :placeholder="'sanaL_hus' | translate"></Input>
                  </FormItem>
                  <FormItem>
                    <Button :disabled="loading" type="primary" class="float-left footer_sanal_btn"
                            @click="dataSent('formValidate')">
                      <span v-if="loading" style="color: #ffffff">{{ '_sending' | translate }}</span>
                      <span v-else>{{ 'send' | translate }}</span>
                    </Button>
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_copyright">
        <p>{{ 'huil_zuin_erkh' | translate }}. {{ 'company_name' | translate }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import {GET_CONTACT_US, POST_FEEDBACK} from "../graphql/queries";
import {mapGetters} from 'vuex';
import axios from 'axios';

export default {
  name: "app-footer",

  data() {
    return {
      loading: false,
      contact: [],
      formValidate: {
        name: '',
        mail: '',
        desc: ''
      },
      ga: {"allTime": "0", "month": "0", "today": "0", "week": "0", "year": "0", "IPAddress":""}
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ]),
    ruleValidate() {
      return {
        name: [
          {required: true, message: this.$t('Please_enter_your_name'), trigger: 'blur'}
        ],
        mail: [
          {required: true, message: this.$t('Enter_your_email_address'), trigger: "blur"},
          {type: "email", message: this.$t("Enter_the_correct_email_address"), trigger: ["blur"],},
        ],
        desc: [
          {required: true, message: this.$t('Write_your_comments'), trigger: 'blur'},
          {type: 'string', min: 20, message: this.$t('Write_at_least_20_characters'), trigger: 'blur'},
          {
            max: 500,
            message: this.$t('Write_maximum_500_characters'),
            trigger: "blur",
          },
        ]

      }
    }

  },
  mounted() {
    this.getContactArray();
    this.gaData();
  },
  methods: {
    gaData() {
      axios.get("https://job-mnca.mn/ga").then(re=>{
        this.ga = re.data;
      })
    },
    getContactArray() {
      this.$apollo.query({query: GET_CONTACT_US}).then(({data}) => {
        this.contact = data.ds_holboo_barih_view[0];
        //console.log(this.contact);
      })
    },
    dataSent(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (name === 'formValidate') {
            this.$apollo.mutate({
              mutation: POST_FEEDBACK,
              variables: {
                name: this.formValidate.name,
                mail: this.formValidate.mail,
                desc: this.formValidate.desc,
              }
            }).then(() => {
              this.$refs[name].resetFields();
              setTimeout(() => {
                this.loading = false;
                this.$Notice.success({title: this.$t("successful"), desc: this.$t("Feedback_sent_successfully")});
              }, 1000)
            })
          }
        } else {
          this.$Notice.error({title: this.$t("failed"), desc: this.$t("Please_fill_field")});
          this.loading = false;
        }
      });
    },
  }


}
</script>

