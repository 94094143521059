import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../pages/about.vue')
  },
  {
    path: '/p_workplace',
    name: 'p_workplace',
    component: () => import(/* webpackChunkName: "standart" */ '../pages/standart.vue')
  },
  {
    //Job posting
    path: '/ajliin_zar',
    name: 'ajliin_zar',
    component: () => import(/* webpackChunkName: "p_job_news" */ '../pages/company/p_job_news.vue')
  },
  {
    //Job posting details
    path: '/b_a_z_details/:id',
    name: 'baiguullaga_ajiliin_zar_details',
    props: true,
    component: () => import(/* webpackChunkName: "baiguullaga_ajiliin_zar_details" */ '../pages/company/baiguullaga_ajiliin_zar_details')
  },
  {
    //Job posting details
    path: '/br_a_z_details/:id',
    name: 'brigad_ajiliin_zar_details',
    props: true,
    component: () => import(/* webpackChunkName: "brigad_ajiliin_zar_details" */ '../pages/company/brigad_ajiliin_zar_details')
  },
  {
    //Workplace posting
    path: '/ajliin_bairnii_zar',
    name: 'ajliin_bairnii_zar',
    component: () => import(/* webpackChunkName: "ajliin_bairnii_zar" */ '../pages/workplaces/workplaces_ad.vue')
  },
  {
    //Workplace posting details
    path: '/b_a_b_zar_details/:id',
    name: 'baiguullaga_ajiliin_bairni_zar_details',
    props: true,
    component: () => import(/* webpackChunkName: "baiguullaga_ajiliin_bairni_zar_details" */ '../pages/workplaces/baiguullaga_ajiliin_bairni_zar_details')
  },
  {
    //Workplace posting details
    path: '/br_a_b_zar_details/:id',
    name: 'brigad_ajiliin_bairnii_zar_details',
    props: true,
    component: () => import(/* webpackChunkName: "brigad_ajiliin_bairnii_zar_details" */ '../pages/workplaces/brigad_ajiliin_bairnii_zar_details')
  },
  {
    //Advice and information details
    path: '/advice_information_list',
    name: 'advice_information_list',
    component: () => import(/* webpackChunkName: "advice_information_list" */ '../pages/advice_information_list.vue')
  },
  {
    //Advice and information details
    path: '/advice_information_details/:id',
    name: 'advice_information_details',
    props: true,
    component: () => import(/* webpackChunkName: "advice_information_details" */ '../pages/advice_information_details.vue')
  },

  {
    path: '/p_company',
    name: 'p_company',
    component: () => import(/* webpackChunkName: "hamtr_baiguullaguud" */ '../pages/hamtr_baiguullaguud.vue')
  },
  {
    path: '/p_news',
    name: 'p_news',
    component: () => import(/* webpackChunkName: "p_news" */ '../pages/medee_medeelel/p_news.vue')
  },
  {
    path: '/p_loginreg',
    name: 'p_loginreg',
    component: () => import(/* webpackChunkName: "p_loginreg" */ '../pages/p_loginreg.vue')
  },
  {
    path: '/news_sub/:id',
    name: 'news_sub',
    component: () => import(/* webpackChunkName: "news_sub" */ '../pages/medee_medeelel/news_sub.vue'),
    props: true
  },
  {
    path: '/tugashariult',
    name: 'tugashariult',
    component: () => import(/* webpackChunkName: "tug_as_hariult" */ '../pages/help.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "tug_as_hariult" */ '../pages/help.vue')
  },
  {
    path: '/standart',
    name: 'standart',
    component: () => import(/* webpackChunkName: "standart" */ '../pages/standart.vue')
  },
  {
    path: '/duremjuram',
    name: 'duremjuram',
    component: () => import(/* webpackChunkName: "durjuram" */ '../pages/durjuram.vue')
  },
  {
    path: '/hamtr_baiguullaguud',
    name: 'hamtr_baiguullaguud',
    component: () => import(/* webpackChunkName: "hamtr_baiguullaguud" */ '../pages/hamtr_baiguullaguud.vue')
  },
  {
    path: '/ajilolgogch',
    name: 'ajilolgogch',
    component: () => import(/* webpackChunkName: "ajilolgogch" */ '../pages/brigad/ajilolgogch.vue')
  },


  // Үйлчилгээний нөхцөл
  {
    path: '/uilchilgeenii_nuhtsul',
    name: 'uilchilgeenii_nuhtsul',
    component: () => import(/* webpackChunkName: "uilchilgeenii_nuhtsul" */ '../pages/uilchilgeenii_nuhtsul')
  },
  {
    path: '/brgd_tanilts/:id',
    name: 'brgd_tanilts',

    component: () => import(/* webpackChunkName: "brgd_tanilts" */ '../pages/brigad/brgd_tanilts'),
    props: true,
  },
  {
    path: '/baiguullaga_tanilts/:ba_id',
    name: 'baiguullaga_tanilts',
    component: () => import(/* webpackChunkName: "baiguullaga_tanilts" */ '../pages/brigad/baiguullaga_tanilts'),
    props: true,
  },


]

const router = new VueRouter({

  base: process.env.BASE_URL,
  routes
})

export default router
