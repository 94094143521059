import gql from 'graphql-tag';

export const SEND_AJLIIN_BAIR_ZAR_ANKET_BAIGUULLAGA = gql`
    mutation createIrtsenAnketBaiguullaga($anket_id: Int!, $baiguullaga_id: Int!, $zar_id: Int!){
        createIrtsenAnketBaiguullaga(input:{anket_id:$anket_id,  baiguullaga_id:$baiguullaga_id, zar_id:$zar_id, tolov_id:1}){
            id

            zar_id
        }
    }
`;

export const SEND_AJLIIN_BAIR_ZAR_ANKET_BRIGAD = gql`
    mutation createIrtsenAnketBrigad($anket_id: Int!, $brigad_id: Int!, $zar_id: Int!){
        createIrtsenAnketBrigad(input:{anket_id:$anket_id,  brigad_id:$brigad_id, zar_id:$zar_id, tolov_id:1}){
            id

            zar_id
        }
    }
`;
export const GET_ILGEESEN_ANKET_BAIGUULLAGA = gql`
query irtsen_anket_baiguullaga($anket_id: String!, $zar_id: String!){
  irtsen_anket_baiguullaga(
    filters: [{ column: "anket_id", condition: equals, value: $anket_id }, { column: "zar_id", condition: equals, value: $zar_id }]
  ) {
    id
    anket_id
    zar_id
  }
}
`;
export const GET_ILGEESEN_ANKET_BRIGAD = gql`
query irtsen_anket_brigad($anket_id: String!, $zar_id: String!){
  irtsen_anket_brigad(
    filters: [{ column: "anket_id", condition: equals, value: $anket_id }, { column: "zar_id", condition: equals, value: $zar_id }]
  ) {
    id
    anket_id
    zar_id
  }
}
`;
export const GET_ANKET = gql`
query engineer_teknical_ajiltan($user_id: String!){
  engineer_teknical_ajiltan(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ) {
    id
  }
}
`;
export const GET_BRIGAD_BAGTS = gql`
query brigad_bagts($brigad_id: String!){
  sub_zar_bagts_gishuun(
    filters:[{column:"brigad_id", condition:equals, value:$brigad_id}], 
    sorts:[{column:"d_ognoo", order:desc}],
    limit:1
  ){
    bagts_id
    baiguullaga_id
    brigad_id
    d_ognoo
    e_ognoo
    id
  },
  sub_zar_bagts_gishuun_bus(
    filters:[{column:"brigad_id", condition:equals, value:$brigad_id}], 
    sorts:[{column:"d_ognoo", order:desc}],
    limit:1
  ){
    bagts_id
    baiguullaga_id
    brigad_id
    d_ognoo
    e_ognoo
    tolov_id
    id
  }
}
`;
export const GET_ORG_BAGTS = gql`
query org_bagts($baiguullaga_id: String!){
  sub_zar_bagts_gishuun(
    filters:[{column:"baiguullaga_id", condition:equals, value:$baiguullaga_id}], 
    sorts:[{column:"d_ognoo", order:desc}],
    limit:1
  ){
    bagts_id
    baiguullaga_id
    brigad_id
    d_ognoo
    e_ognoo
    id
  },
  sub_zar_bagts_gishuun_bus(
    filters:[{column:"baiguullaga_id", condition:equals, value:$baiguullaga_id}], 
    sorts:[{column:"d_ognoo", order:desc}],
    limit:1
  ){
    bagts_id
    baiguullaga_id
    brigad_id
    d_ognoo
    e_ognoo
    tolov_id
    sub_type
    id
  }
}
`;

export const BUYE_BAGTS = gql`
    mutation createSubZarBagtsGishuunBus($bagts_id: Int, $brigad_id: Int, $baiguullaga_id: Int, $e_ognoo: Date, $d_ognoo: Date, $une: Int, $sub_type: Int){
        createSubZarBagtsGishuunBus(input:{bagts_id:$bagts_id,  brigad_id:$brigad_id, baiguullaga_id:$baiguullaga_id, tolov_id:1, e_ognoo:$e_ognoo, d_ognoo:$d_ognoo, une: $une, sub_type:$sub_type}){
             bagts_id
            baiguullaga_id
            brigad_id
            d_ognoo
            e_ognoo
            tolov_id
            sub_type
            id
        }
    }
`;
