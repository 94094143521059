export default {
    "home": "НҮҮР",
    "uilch_nuhtsul": "Үйлчилгээний нөхцөл",
    "help_v": "Тусламж",
    "holboo_barih": "Холбоо барих",
    "loginreg": "Нэвтрэх",
    "_sending": "Илгээж байна...",
    "news": "Мэдээ, мэдээлэл",
    "dur_juram": "Дүрэм, журам",
    "advice_information": "Зөвлөгөө, мэдээлэл",
    "sanaL_hus": "Санал хүсэлт",
    "hadgalsan":"Хадгалсан",
    "medegdel":"Мэдэгдэл",
    "ajliin_bairnii_zar": "ЗАРЛАГДСАН АЖЛЫН БАЙР",
    "zahialgat_ajil": "ЗАХИАЛГАТ АЖИЛ",
    "Ajil_olgogch": "АЖИЛ ОЛГОГЧ",
    "hailt":"Хайлт...",
    "log-in":"НЭВТРЭХ",
    "register":"БҮРТГҮҮЛЭХ",
    "log-in1":"Нэвтрэх",
    "register1":"Бүртгүүлэх",
    "email":"И-мэйл",
    "pass":"Нууц үг",
    "forgot_pass":"Нууц үг ээ мартсан",
    "last_name":"Эцэг, Эхийн нэр",
    "first_name":"Нэр",
    "date_of_birth":"Таны төрсөн огноо",
    "gender":"Хүйс",
    "male":"Эрэгтэй",
    "female":"Эмэгтэй",
    "phone":"Утасны дугаар",
    "confirm_pass":"Нууц үгээ дахин оруулна уу",
    "uilchilgeenii":"ҮЙЛЧИЛГЭЭНИЙ",
    "nuhtsul":"НӨХЦӨЛ",
    "uilchilgeenii_nukhtsul":"ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ",
    "gishuun_bagts":"МБҮА ТББ-ЫН ГИШҮҮДИЙН БАГЦ",
    "gishuun_bus_bagts":"ХЭРЭГЛЭГЧИЙН БАГЦ",
    "watch":"Үзэх",
    "gold":"АЛТАН БАГЦ",
    "gold_presidents":"МБҮА ТББ-ын Ерөнхийлөгчид",
    "silver":"МӨНГӨН БАГЦ",
    "silver_members":"МБҮА ТББ-ын УЗГишүүд",
    "bronze":"ХҮРЭЛ БАГЦ",
    "bronze_members":"МБҮА ТББ-ын Гишүүд",
    "observer_member_set":"АЖИГЛАГЧ ГИШҮҮН БАГЦ",
    "service_date":"Үйлчилгээний хугацаа",
    "job_posting":"Ажлын зар",
    "application_base":"Анкет бааз",
    "other":"Бусад",
    "package_service":"Багцын үйлчилгээ үнэгүй",
    "package_service1":"Багцын үйлчилгээний төлбөр",
    "common":"ТҮГЭЭМЭЛ",
    "Q_and_A":"АСУУЛТ, ХАРИУЛТ",
    "select":"Сонгох",
    "sistemiin_hundalt":"Системийн хандалт",
    "send":"Илгээх",
    "name":"Нэр",
    "tsahim_hayag":"Цахим хаяг",
    "today":"Өнөөдөр",
    "week":"Энэ долоо хоногт",
    "month":"Энэ сар",
    "year":"Энэ жил",
    "total":"Нийт",
    "huil_zuin_erkh":"Зохиогчийн эрх хуулиар хамгаалагдсан © 2022",
    "company_name":"Монголын Барилгын Үндэсний Ассоциаци ТББ",
    "news1":"Мэдээ,",
    "information":"Мэдээлэл",
    "advice":"Зөвлөгөө,",
    "durem":"ДҮРЭМ",
    "juram":"ЖУРАМ",
    "this_month":"Энэ сард",
    "this_year":"Энэ жилд",
    "baiguullaga": "Байгууллага",
    "brigad": "Бригад",
    "total_ad": "Нийт зар",
    "total_employer": "Нийт ажил олгогч",
    "zarlagdsan":"Зарлагдсан",
    "ajliin_zar":"Ажлын байрны зар",
    "bugdiig_harah":"Бүгдийг харах",
    "where":"Хаана",
    "zarlasan_ognoo":"Зарласан огноо",
    "anket_avah_ognoo":"Анкет авах огноо",
    "detail":"Дэлгэрэнгүй",
    "zahialgat":"Захиалгат",
    "ajil":"ажил",
    "guitsetgegch":"Гүйцэтгэгч",
    "hamtragch":"Хамтрагч",
    "baiguullaguud":"Байгууллагууд",
    "mergejliin_chigleleer":"Мэргэжлийн чиглэлээр",
    "mergejleer":"Мэргэжлээр",
    "mergejil_songoh":"Мэргэжил сонгох",
    "mergejil":"Мэргэжил",
    "tsagiin_turul":"Ажиллах цагийн төрөл",
    "tsalin_huls":"Ажлын цалин хөлс",
    "chiglel_songoh":"Чиглэл сонгох",
    "baiguullagaar_haih":"Байгууллагаар хайх",
    "brigadaar_haih":"Бригадаар хайх",
    "tsagiin_turul_songoh":"Цагийн төрөл сонгоно уу",
    "tsalingiin_unelgee_songoh":"Цалингийн үнэлгээ сонгоно уу",
    "dulaan_shiidel":"Дулаан шийдэл төсөл",
    "ontslokh":"ОНЦЛОХ",
    "ajliin_turul":"Ажлын төрөл",
    "job":"АЖЛЫН ТӨРӨЛ",
    "ajliin_turul_songoh":"Ажлын төрөл сонгох",
    "guitsetgeh_undsen_uureg":"ГҮЙЦЭТГЭХ ҮНДСЭН ҮҮРЭГ",
    "tavigdah_shaardlaga":"ТАВИГДАХ ШААРДЛАГА",
    "nemelt_medeelel":"НЭМЭЛТ МЭДЭЭЛЭЛ",
    "busad_medeelel":"БУСАД МЭДЭЭЛЭЛ",
    "tsalin_hulsnii_turul":"Цалин хөлсний төрөл",
    "aimag_niislel":"Аймаг / Нийслэл",
    "sum_duureg":"Сум / Дүүрэг",
    "bag_horoo":"Баг / Хороо",
    "anket_ilgeeh":"АНКЕТ ИЛГЭЭХ",
    "sanal_ilgeeh":"САНАЛ ИЛГЭЭХ",
    "ijil_ajliin_zar":"ТУС АЖЛЫН ТӨРЛИЙН ИЖИЛ ЗАРУУД",
    "zarlagdsan_ijil_zaruud":"БУСАД ЗАРУУД",
    "hun":"ТУХАЙН АЖЛЫГ ГҮЙЦЭТГЭХЭД ШААРДЛАГАТАЙ МЭРГЭЖИЛ БОЛОН АЖИЛТАН",
    "tuz":"ТУЗ",
    "salbar":"Салбарын",
    "tsagUyiin":"Цаг үеийн",
    "dagah":"Дагасан",
    "garah":"Гарах",
    "anket_bugluh":"Анкет бөглөх",
    "brigad_udirdlaga":"Баригадын удирдлага",
    "baiguullagiin_udirdlaga":"Байгууллагийн удирдлага",
    "amjilttai_nevtrelt":"Амжилттай нэвтэрлээ. Түр хүлээнэ үү!",
    "netrehed_aldaa":"Нэвтрэх явцад алдаа гарлаа. Нэвтрэх мэдээллээ шалгаад дахин оролдоно уу?",
    "nevtreh_medeelel_buruu":"Нэвтрэх мэдээлэл буруу байна.",
    "hadgalsan_ajliin_bairnii_zar": "Хадгалсан ажлын байрны зар",
    "hadgalsan_zahialgat_ajil": "Хадгалсан ажлын зар",
    "ankit_ilgeesen":"Анкет илгээсэн",
    "baiguullagin_ajliin_bairnii_zar":"БАЙГУУЛЛАГЫН АЖЛЫН БАЙРНЫ ЗАР",
    "brigad_ajliin_bairnii_zar":"БРИГАД АЖЛЫН БАЙРНЫ ЗАР",
    "baiguullagin_ajliin_zar":"БАЙГУУЛЛАГЫН АЖЛЫН ЗАР",
    "brigad_ajliin_zar":"БРИГАД АЖЛЫН ЗАР",
    "ta_ene_bagts_avah_uu":"Та энэ багцыг авах уу?",
    "ugui":"Үгүй",
    "tiim":"Тийм",
    "bagts_avah":"Багц авах",
    "avsan_bagts":"Авсан багц",
    "shiljuulgiin_medeelel":"ШИЛЖҮҮЛГИЙН МЭДЭЭЛЭЛ",
    "shiljuuleh_dans":"Шилжүүлэх данс",
    "huleen_avagch":"Хүлээн авагч",
    "guilgeenii_utga":"Гүйлгээний утга",
    "tuluv":"Төлөв",
    "huchintei_hugatsaa":"Хүчинтэй хугацаа",
    "huleegdej_bui":"Хүлээгдэж буй",
    "batalgaajsan":"Баталгаажсан",
    "butsaagdsan":"Буцаагдсан",
    "mnca":"МОНГОЛЫН БАРИЛГЫН ҮНДЭСНИЙ АССОЦИАЦ ТББ",
    "hudaldaa_hugjliin_bank":"Худалдаа хөгжлийн банк 499136297",
    "idevhtei_bagts":"Идэвхтэй багц",
    "aldaa":"Error",
    "aimagcapitalcity":"Аймаг нийслэл сонгох",
    "sumdistrict":"Сум дүүрэг сонгох",
    "olgogch":"ОЛГОГЧ",
    "ankit":"Анкет",
    "successful":"Амжилттай",
    "no_saved_organization":"Хадгалсан байгууллага байхгүй байна.",
    "no_saved_brigade":"Хадгалсан бригад байхгүй байна.",
    "online":"Вэб сайтад яг одоо онлайн хүн байна.",
    "your_ip":"Таны IP:",
    "i_agree":"Би зөвшөөрч байна.",
    "Get_acquainted_terms_of_service":"Үйлчилгээний нөхцөлтэй танилцах",
    "Date_of_publication":"Нийтэлсэн огноо",
    "Expiry_date":"Дуусах огноо",
    "no_saved_ads.":" Хадгалсан зар байхгүй байна.",
    "Expiry":"Дуусах",
    "date":"огноо",
    "publication":"Нийтэлсэн",
    "p_date":"огноо",
    "clear":"Цэвэрлэх",
    "employerStatus":"АЖИЛ ОЛГОГЧИЙН ТӨЛӨВ",
    "direction_activity":"ҮЙЛ АЖИЛЛАГААНЫ ЧИГЛЭЛ",
    "Choose_status":"Төлөв сонгох",
    "Choose_field_activity":"Үйл ажилгааны чиглэл сонгох",
    "search":"ХАЙХ",
    "introduction":"Танилцуулга",
    "partner_organizations":"ХАМТРАГЧ БАЙГУУЛЛАГУУД",
    "payment":"Төлбөр тооцоо",
    "work_carried_out_according_plan":"Гүйцэтгэх ажлын явц төлөвлөгөөны дагуу явагдсан эсэх",
    "Making_optimal_decisions_progress_work":"Тухайн ажлын гүйцэтгэлийн явцын оновчтой шийдвэр гаргалт",
    "performed_works":"ГҮЙЦЭТГЭСЭН АЖЛУУД",
    "projects":"ТӨСЛҮҮД",
    "inProgress":"Хийгдэж байна.",
    "customer":"Захиалагч",
    "start_date":"Эхэлсэн огноо",
    "completion_date":"Дууссан огноо",
    "There_no_work_done":"Гүйцэтгэсэн ажил байхгүй байна.",
    "no_job_postings":"Зарласан ажлын зар байхгүй байна.",
    "no_ads":"Зар байхгүй байна.",
    "Date_commencement_operations":"Үйл ажиллагаа эхэлсэн огноо",
    "ajliin_bairnii_zaruud": "АЖЛЫН БАЙРНЫ ЗАРУУД",
    "zahialgat_ajil_zaruud": "АЖЛЫН ЗАРУУД",
    "hayag":"хаяг",
    "Details_work_performed":"Гүйцэтгэсэн ажлын дэлгэрэнгүй",
    "There_picture_work_done":"Гүйцэтгэсэн ажлын тухай зураг байхгүй",
    "Date_commencement_work":"Ажил эхэлсэн огноо",
    "Date_completion_work":"Ажил дууссан огноо",
    "evaluation_customer_performance":"ЗАХИАЛАГЧААС ГҮЙЦЭТГЭЛД ӨГСӨН ҮНЭЛГЭЭ",
    "elaluation_of_customers":"ЗАХИАЛАГЧИД ӨГСӨН ҮНЭЛГЭЭ",
    "contract_performance":"Гэрээт ажлын гүйцэтгэл",
    "Capacity_competence_employees":"Ажилчдын чадавх, чадамж",
    "Responsibility_work_performed":"Гүйцэтгэсэн ажлын хариуцлага",
    "correcthess":"Цаг барих",
    "send_feedback":"Санал хүсэлт илгээх",
    "ORGANIZATION_NOT_FOUND":"БАЙГУУЛЛАГА ОЛДСОНГҮЙ",
    "BRIGADE_NOT_FOUND":"БРИГАД ОЛДСОНГҮЙ",
    "Send_a_quote":"Үнийн санал илгээх",
    "LOOKING_FOR_JOB":"АЖЛЫН БАЙР ХАЙХ",
    "VIDEO":"ВИДЕО",
    "COMMON_QUESTIONS_AND_ANSWERS":"ТҮГЭЭМЭЛ АСУУЛТ ХАРИУЛТ",
    "successfully_Deleted":"Амжилттай устлаа",
    "Your_application_successfully_submitted":"Таны анкет амжилттай илгээгдлээ.",
    "failed":"Амжилтгүй",
    "Feedback_sent_successfully":"Санал хүсэлт амжилттай илгээгдлээ.",
    "Please_fill_field":"Талбарыг гүйцэт бөглөнө үү !!!",
    "error":"Алдаа",
    "error_occurred_submitting":"Илгээх үед алдаа гарлаа.",
    "Your_account_created_successfully":"Таны бүртгэл амжилттай үүслээ.",
    "Successfully_saved":"Амжилттай хадгалагдлаа.",
    "Followed_organization_menu":"Дагасан байгууллага цэс рүү орлоо.",
    "Followed_brigade_menu":"Дагасан бригад цэс рүү орлоо.",
    "Your_saved_ads_Saved_Jobs_ads_menu":"Таны хадгалсан зар Хадгалсан ажлын байр зар цэсрүү орлоо.",
    "Your_saved_ads_Saved_Works_ads_menu":"Таны хадгалсан зар Хадгалсан ажлын зар цэсрүү орлоо.",
    "Notices":"МЭДЭГДЛҮҮД",
    "Please_enter_your_name":"Та нэрээ бичнэ үү!",
    "Enter_your_email_address":"Та и-мэйл хаягаа бичнэ үү!",
    "Enter_the_correct_email_address":"Зөв и-мейл хаяг бичнэ үү!",
    "Write_your_comments":"Санал хүсэлтээ бичнэ үү!",
    "Write_at_least_20_characters":"Хамгийн багадаа 20 тэмдэгт бичнэ үү!",
    "Write_maximum_500_characters":"Хамгийн ихдээ 500 тэмдэгт бичнэ үү!",
    "name_cannot_empty":"Нэр хоосон байж болохгүй!",
    "Enter_your_password":"Нууц үгээ оруулна уу!",
};
