import gql from 'graphql-tag';

export const IMAGE_URL = "https://job-mnca.mn";

export const GET_DUREM_ANGILAL = gql`{
  ds_durem_angilal_view {
    duremangilal
    duremangilal_en
    id
  }
}
`;
export const GET_DUREM = gql`
query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size)
  {
    page
    total
    last_page
    ds_durem_juram_view {
      angilal_id
      created_at
      deleted_at
      durem_en
      durem_mn
      duremangilal
      file
      id
      link
      updated_at
    }
   }
}
`;
export const GET_DUREM_BY_ID = gql`
query paginate($page:Int!, $size:Int!, $cat_id: String!){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
  filters: [{ column: "angilal_id", condition: equals, value: $cat_id }]
  )
  {
    page
    total
    last_page
    ds_durem_juram_view {
      angilal_id
      created_at
      deleted_at
      durem_en
      durem_mn
      duremangilal
      file
      id
      link
      updated_at
    }
   }
}
`;

export const GET_BAIGUULLAGA = gql`{
  ds_baiguullaga{
    aimag_id
    aimagname
    b_ognoo
    bag_id
    bair_toot
    bidnii_tuhai
    buatz_eseh
    created_at
    d_sar
    deleted_at
    e_sar
    eseh
    facebook
    fax
    file_tz
    gudamj
    hh_tatvar_eseh
    id
    linkedin
    logo
    mail
    nd_eseh
    ner_en
    ner_mn
    no_eseh
    page
    phone_one
    phone_two
    sum_id
    sumname
    tolov
    tolov_id
    twitter
    updated_at
    zurag
    torolgishuun
    gishuunbus_torol
    ds_ajiliin_zar_view {
       ajiliin_torol
       id
    }
    ds_ajil_bair_zar_view {
       ajiliin_torol
       id
    }
  }
}
`;
export const GET_BAIGUULLAGA_LIMIT_TEN = gql`{
  ds_baiguullaga(limit:10) {
    aimag_id
    aimagname
    b_ognoo
    bag_id
    bair_toot
    bidnii_tuhai
    buatz_eseh
    created_at
    d_sar
    deleted_at
    e_sar
    eseh
    facebook
    fax
    file_tz
    gudamj
    hh_tatvar_eseh
    id
    linkedin
    logo
    mail
    nd_eseh
    ner_en
    ner_mn
    no_eseh
    page
    phone_one
    phone_two
    sum_id
    sumname
    tolov
    tolov_id
    twitter
    updated_at
    zurag
    torolgishuun
    gishuunbus_torol
    ds_ajiliin_zar_view {
       ajiliin_torol
       id
    }
    ds_ajil_bair_zar_view {
       ajiliin_torol
       id
    }
  }
}
`;
export const GET_BAIGUULLAGA_LIMIT_FEFTIIN = gql`{
  ds_baiguullaga(limit:15) {
    aimag_id
    aimagname
    b_ognoo
    bag_id
    bair_toot
    bidnii_tuhai
    buatz_eseh
    created_at
    d_sar
    deleted_at
    e_sar
    eseh
    facebook
    fax
    file_tz
    gudamj
    hh_tatvar_eseh
    id
    linkedin
    logo
    mail
    nd_eseh
    ner_en
    ner_mn
    no_eseh
    page
    phone_one
    phone_two
    sum_id
    sumname
    tolov
    tolov_id
    twitter
    updated_at
    zurag
    torolgishuun
    gishuunbus_torol
    ds_ajiliin_zar_view {
       ajiliin_torol
       id
    }
    ds_ajil_bair_zar_view {
       ajiliin_torol
       id
    }
  }
}
`;

export const GET_BAIGUULLAGA_SRCH_ALL = gql`
query ds_baiguullaga($tolov_id: String!){
  ds_baiguullaga(
    filters: [{ column: "tolov_id", condition: equals, value: $tolov_id }]
  ){
    id
    logo
    buatz_eseh
    ner_en
    ner_mn
    no_eseh
    tolov
    tolov_id
  }
}
`;

export const GET_BAIGUULLAGA_SRCH_ID = gql`
query ds_baiguullaga_view($b_id: String!){
  ds_baiguullaga_view(
    filters: [{ column: "id", condition: equals, value: $b_id}]
  ){
      acc_check
      aimag_id
      aimagname
      alban_tushaalass
      b_ognoo
      bag_id
      bagname
      bagsh_bus_id
      bagts_id
      baiguullaga_ner
      bair_toot
      bidnii_tuhai
      borluulalt_orlogo
      brigad_id
      buatz_eseh
      created_at
      d_sar
      deleted_at
      duusah_ognoo
      e_sar
      ehleh_ognoo
      eseh
      facebook
      fax
      file_tz
      gishuunbus_torol
      gishuunii_dugaar
      gudamj
      hh_tatvar_eseh
      hugatsaa_id
      id
      linkedin
      logo
      mail
      nd_eseh
      ner_en
      ner_mn
      ner_udirdlaga
      ner_udirdlagamedeelel
      no_eseh
      page
      phone_one
      phone_two
      sum_id
      sumname
      surguuli_udir
      tolov
      tolov_id
      torolgishuun
      twitter
      udirdah_ajiltan
      updated_at
      zurag
      ds_hamtragch_baiguullaga_view{
          hamtragch_baiguullaga
          id
          ner_mn
          logo
          hamtragch_id
        }
      ds_sub_baiguullaga_uil_ajilgaa_view{
          ajilgaa_id
          b_id
          id
          uilajilgaa
    }
  }
}
`;


export const GET_BAIGUULLAGA_MULTI_SRCH = gql`
query ds_baiguullaga($tolov_id: String!){
  ds_baiguullaga_view(
    filters: [{ column: "tolov_id", condition: equals, value: $tolov_id}]
  ){
      id
      logo
      ner_mn
      tolov
      tolov_id
      zurag
  }
}
`;


//BAIGUULLAGA AJIL OLGOGCH PAGE - XAILT QUERY
export const GET_BAIGUULLAGA_SRCH_MULTI_CONDITION = gql`
query paginate($page:Int!, $size:Int!, $tolov_id: String!, $uajilgaa: String!, $aimagid: String!, $sumduureg: String!)
{
    paginate(
        sorts: [{column: "id", order: desc}], page: $page, size: $size,
        groupFilters: [
        {
            combine: or
            filters: [
                {column: "tolov_id", condition: equals, value: $tolov_id},
                {column: "id_uil_ajilgaa", condition: equals, value: $uajilgaa},
                {column: "aimag_id", condition: equals, value: $aimagid},
                {column: "sum_id", condition: equals, value: $sumduureg}
            ]
        }
    ]
)
    {
            page
            total
            last_page
            ds_baiguullaga_view
            {
                acc_check
                alban_tushaalass
                bagsh_bus_id
                bagts_id
                baiguullaga_ner
                brigad_id
                buatz_eseh
                gishuunbus_torol
                gishuunii_dugaar
                id
                logo
                ner_en
                ner_mn
                ner_udirdlaga
                ner_udirdlagamedeelel
                no_eseh
                surguuli_udir
                tolov
                tolov_id
                torolgishuun
                zurag

            }
    }
}
`;

//BRIGAD AJIL OLGOGCH PAGE - XAILT QUERY
export const GET_BRIGAD_SRCH_MULTI_CONDITION = gql`
query paginate($page:Int!, $size:Int!, $aimagid: String!, $sumduureg: String!)
{
    paginate(
        sorts: [{column: "id", order: desc}], page: $page, size: $size,
        groupFilters: [
        {
            combine: or
            filters: [
                 
                {column: "aimag_id", condition: equals, value: $aimagid},
                {column: "sum_id", condition: equals, value: $sumduureg}
            ]
        }
    ]
)
    {
            page
            total
            last_page
            ds_brilgas_view
            { 
                id
                logo
                ner_mn
                torol_gishuun
                torol_gishuun_bus
    
            }
    }
}
`;


export const GET_BRIGAD_SRCH_ID = gql`
query ds_brilgas_view($b_id: String!){
  ds_brilgas_view(
    filters: [{ column: "id", condition: equals, value: $b_id}]
  ){
      ahlagch_zurag
      aimag_id
      aimagname
      alban_tatvar_eseh
      bag_id
      bagname
      baiguullaga_ner
      banner
      borluulalt_orlogo
      created_at
      deleted_at
      e_ognoo
      face
      gudam
      huis
      id
      linked_in
      logo
      mail
      nd_toldog_eseh
      ner
      ner_en
      ner_mn
      noat_eseh
      on_jil_id
      ovog
      register
      sum_id
      sumname
      surguuli
      toot
      torol_gishuun
      torol_gishuun_bus
      tuhai
      twitter
      utas_one
      utas_two
      updated_at
      web
      ds_sub_brigad_uil_ajilgaa_chiglel_view{
        brigad_id
        brigad_uil_ajilgaa_chiglelcol
        id
        uil_id
      }
      ds_hamtragch_baiguullaga_view{
        b_id
        brigad_id
        hamtragch_baiguullaga
        hamtragch_id
        id
        logo
        ner_en
        ner_mn
      }
      
  }
}
`;


export const GET_BRIGAD_SRCH_ALL_AOP = gql`
query paginate($page:Int!, $size:Int!){
    paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size)
    {
        page
        total
        last_page
        ds_brilgas_view{
            id
            logo
            ner_mn
            torol_gishuun
            torol_gishuun_bus
        }
    }
}
`;


export const GET_BAIGUULLAGA_SRCH_ALL_AOP = gql`
query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size)
  {
    page
    total
    last_page
    ds_baiguullaga_view {
            acc_check
              alban_tushaalass
              bagsh_bus_id
              bagts_id
              baiguullaga_ner
              brigad_id
              buatz_eseh
              gishuunbus_torol
              gishuunii_dugaar
              id
              logo
              ner_en
              ner_mn
              tolov
              tolov_id
              torolgishuun
              zurag
    }
   }
}
`;


export const GET_BAIGUULLAGA_ITA_VIEW = gql`{
  ds_baiguullaga_ita_medeelel_view {
    ajil_erhelelt_tolov_id
    b_id
    erhlelt_tolov
    id
    inj_ta_id
    ner
    ner_mn
    ovog
    tailbar
    unelgee
    unelgee_id
  }
}
`;


export const GET_GUITSETGESEN_AJIL_VIEW = gql`{
  ds_guitsetgesen_ajil_view {
    aimag_id
    aimagname
    ajil_guitsetgsen_id
    alban_tushaaltan
    b_id
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    guitsetgsen_ajil
    habe_eseh
    id
    mail
    ner_mn_baiguullaga
    sum_id
    sumname
    toim
    updated_at
    utas
    zahialagch
    zurag
  }
}
`;

export const GET_BAIGUULLAGA_GUITSET_AJIL_B_ID = gql`
query paginate($page:Int!, $size:Int!, $ba_id: String!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size, filters: [{ column: "b_id", condition: equals, value: $ba_id}])
  {
    page
    total
    last_page
    ds_guitsetgesen_ajil_view {
    aimag_id
    aimagname
    ajil_guitsetgsen_id
    guitsetgsen_ajil
    e_ognoo
    du_ognoo
    b_id
    alban_tushaaltan
    id
    ner_mn_baiguullaga
    mail
    sum_id
    sumname
    toim
    zurag
    }
   }
}
`;
export const GET_BRIGAD_GUITSET_AJIL_ID = gql`
query paginate($page:Int!, $size:Int!, $id: String!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size, filters: [{ column: "br_id", condition: equals, value: $id}])
  {
    page
    total
    last_page
    ds_brigad_guitsetgesen_ajil_view {
      aimagname
      ajil_guitsetgsen_id
      brigad_ner
      brigad_uil_ajilgaa_chiglelcol
      d_ognoo
      e_ognoo
      guitsetgsen_ajil
      id
      zurag
    }
   }
}
`;

export const GET_BAIGUULLAGA_GUITSET_AJIL_DEL = gql`
query ds_guitsetgesen_ajil_view($gid: String!){
  ds_guitsetgesen_ajil_view(
    filters: [{ column: "id", condition: equals, value: $gid}]
  ){
    acc_check
    aimag_id
    aimagname
    ajil_guitsetgsen_id
    alban_tushaaltan
    b_id
    bag_id
    bagname
    created_at
    du_ognoo
    e_ognoo
    deleted_at
    guitsetgsen_ajil
    habe_eseh
    id
    ner_mn_baiguullaga
    sum_id
    sumname
    toim
    updated_at
    utas
    mail
    zahialagch_id
      zahiral
      zurag
      ds_sub_baiguullaga_uneleh_veiw{
        ajil_id
        b_unelgee
        baiguullaga_id
        id
        ner_baiguullaga
        tailbar
        unelgee
        unelgee_id
        uzuulelt_id        
      sub_sub_baiguullaga_unelgee{
            id
          tailbar
          u_bai_table_id
          unelgee_id
          uzuulelt_id
      }
      }   
  }
}
`;


export const GET_BRIGAD_GUITSET_AJIL_DEL = gql`
query ds_brigad_guitsetgesen_ajil_view($gid: String!){
  ds_brigad_guitsetgesen_ajil_view(
    filters: [{ column: "id", condition: equals, value: $gid}]
  ){
        aimag_id
        aimagname
        ajil_guitsetgsen_id
        alban_tatvar_eseh
        alban_tushaaltan
        borluulalt_orlogo
        br_id
        brigad_ner
        brigad_uil_ajilgaa_chiglelcol
        created_at
        d_ognoo
        deleted_at
        e_ognoo
        guitsetgsen_ajil
        habe_eseh
        id
        mail
        nd_toldog_eseh
        noat_eseh
        on_jil_id
        sum_id
        sumname
        toim
        updated_at
        utas
        zahialagch_id
        zurag
  }
}
`;


export const GET_SHAGNAL_URAMSHUULAL_VIEW = gql`{
  ds_shagnal_uramshuulal_view {
    b_id
    created_at
    deleted_at
    id
    ner
    ner_mn
    ognoo
    ologson_baiguullaga
    updated_at
    zurag   
  }
}
`;
export const GET_TEKNIK_TT_VIEW = gql`{
  ds_teknik_tt_view {
    b_id
    busad
    id
    model
    ner_en_baiguullga
    ner_mn_baiguullga
    too
    tt
    uzuulelt
    zoriulalt
    zurag
  }
}
`;

// AJILIIN ZAR START

export const GET_AJILIIN_ZAR_VIEW = gql`{
  ds_ajiliin_zar_view {
    acc_check
    aimag_id
    aimagname
    ajil
    ajil_hemjee
    ajil_torol_id
    ajiliin_torol
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    logo
    deleted_at
    e_ognoo
    guitsetgsen_undsen_uureg
    id
    nemelt
    ner_mnbaiguullaga
    sanalbolgoh_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_torol_id
    tsagiin_torol
    tsalin_torol_id
    torol_bagts
    torolbagts_gishuun_bus
  }
}
`;
export const GET_AJILIIN_ZAR_VIEW_LIMIT5 = gql`{
  ds_ajiliin_zar_view(limit:5, sorts: [{ column: "id", order: desc }]) {
    acc_check
    aimag_id
    aimagname
    ajil
    ajil_hemjee
    ajil_torol_id
    ajiliin_torol
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    logo
    deleted_at
    e_ognoo
    guitsetgsen_undsen_uureg
    id
    nemelt
    ner_mnbaiguullaga
    sanalbolgoh_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_torol_id
    tsagiin_torol
    tsalin_torol_id
    torol_bagts
    torolbagts_gishuun_bus
  }
}
`;
export const GET_BRGD_AJILIIN_ZAR_VIEW = gql`{
  ds_brigad_ajil_zar_view {
    aimag_id
    aimagname
    ajil
    ajil_hemjee
    ajil_torol_id
    ajiliin_torol
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    ner_mnbrigad
    e_ognoo
    logo
    id
    nemelt
    sanalbolgoh_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_torol_id
    tsagiin_torol
    tsalin_torol
    tsalin_torol_id
    uureg
    torol_gishuun
    torol_gishuun_bus
  }
}
`;
export const GET_BRGD_AJILIIN_ZAR_VIEW_LIMIT5 = gql`{
  ds_brigad_ajil_zar_view(limit:5, sorts: [{ column: "id", order: desc }]) {
    aimag_id
    aimagname
    ajil
    ajil_hemjee
    ajil_torol_id
    ajiliin_torol
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    ner_mnbrigad
    e_ognoo
    logo
    id
    nemelt
    sanalbolgoh_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_torol_id
    tsagiin_torol
    tsalin_torol
    tsalin_torol_id
    uureg
    torol_gishuun
    torol_gishuun_bus
  }
}
`;

export const GET_AJILIIN_ZAR_VIEW_DETAILS = gql`
query ds_ajiliin_zar_view($z_id: String!){
  ds_ajiliin_zar_view(
    filters: [{ column: "id", condition: equals, value: $z_id }]
  ){
    acc_check
    aimag_id
    aimagname
    ajil
    ajil_hemjee
    ajil_torol_id
    ajiliin_torol
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    guitsetgsen_undsen_uureg
    logo
    id
    nemelt
    ner_mnbaiguullaga
    sanalbolgoh_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_torol_id
    tsagiin_torol
    tsalin_torol_id
    tsalin_torol
    mergejil_teknik_ajiltan
    too_teknik_ajiltan
    mergejil_enginer
    too_
    twitter
    phone_one
    phone_two
    mail
    linkedin
    facebook
    gudamj
    page
    bair_toot
  }
}
`;
export const GET_BRGD_AJILIIN_ZAR_VIEW_DETAILS = gql`
query ds_brigad_ajil_zar_view($b_id: String!){
  ds_brigad_ajil_zar_view(
    filters: [{ column: "id", condition: equals, value: $b_id }]
  ){
    aimag_id
    aimagname
    ajil
    ajil_hemjee
    ajil_torol_id
    ajiliin_torol
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    ner_mnbrigad
    e_ognoo
    logo
    id
    nemelt
    sanalbolgoh_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_torol_id
    tsagiin_torol
    tsalin_torol
    tsalin_torol_id
    uureg
    ajitan_teknik
    toos
    face
    mail
    twitter
    utas_one
    utas_two
    web
    gudam
    toot
  }
}
`;

export const GET_AJILIIN_ZAR_PAGE = gql`
query paginate($page:Int!, $size:Int!){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
  )
  {
    page
    total
    last_page
    ds_ajiliin_zar_view {
      acc_check
      aimag_id
      aimagname
      ajil
      ajil_hemjee
      ajil_torol_id
      ajiliin_torol
      b_id
      bag_id
      bagname
      created_at
      d_ognoo
      deleted_at
      e_ognoo
      guitsetgsen_undsen_uureg
      logo
      id
      nemelt
      ner_mnbaiguullaga
      sanalbolgoh_tsalin
      shaardlaga
      sum_id
      sumname
      tsag_torol_id
      tsagiin_torol
      tsalin_torol_id
      torolbagts_gishuun_bus
      torol_bagts
      torolbagts_gishuun_bus
    }
   }
}
`;
export const GET_BRGD_AJILIIN_ZAR_PAGE = gql`
query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size,)
  {
    page
    total
    last_page
    ds_brigad_ajil_zar_view {
     aimag_id
     aimagname
     ajil
     ajil_hemjee
     ajil_torol_id
     ajiliin_torol
     b_id
     bag_id
     bagname
     created_at
     d_ognoo
     deleted_at
     ner_mnbrigad
     e_ognoo
     logo
     id
     nemelt
     sanalbolgoh_tsalin
     shaardlaga
     sum_id
     sumname
     tsag_torol_id
     tsagiin_torol
     tsalin_torol
     tsalin_torol_id
     uureg
     torol_gishuun
     torol_gishuun_bus
    }
   }
}
`;

export const GET_AJIL_ZAR_PAGE_BY_ID = gql`
query paginate($page:Int!, $size:Int!, $baiguullaga_id:String!, $ts_turul_id:String!, $tsalin_turul_id:String!, $ajiliin_turul_id:String!,  $aimag_niislel:String!,  $sum_duureg:String!, ){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
  groupFilters: [
        {
            combine: or
            filters: [
                { column: "b_id", condition: equals, value: $baiguullaga_id },
                { column: "tsag_torol_id", condition: equals, value: $ts_turul_id },
                { column: "tsalin_torol_id", condition: equals, value: $tsalin_turul_id },
                { column: "ajil_torol_id", condition: equals, value: $ajiliin_turul_id },
                { column: "aimag_id", condition: equals, value: $aimag_niislel },
                { column: "sum_id", condition: equals, value: $sum_duureg },
            ]
        }
      ]
  )
  {
    page
    total
    last_page
    ds_ajiliin_zar_view {
      acc_check
      aimag_id
      aimagname
      ajil
      ajil_hemjee
      ajil_torol_id
      ajiliin_torol
      b_id
      bag_id
      bagname
      created_at
      d_ognoo
      deleted_at
      e_ognoo
      guitsetgsen_undsen_uureg
      logo
      id
      nemelt
      ner_mnbaiguullaga
      sanalbolgoh_tsalin
      shaardlaga
      sum_id
      sumname
      tsag_torol_id
      tsagiin_torol
      tsalin_torol_id
      torol_bagts
      torolbagts_gishuun_bus
    }
   }
}
`;
export const GET_BRGD_AJIL_ZAR_PAGE_BY_ID = gql`
query paginate($page:Int!, $size:Int!, $brigad_id:String!, $ts_turul_id:String!, $tsalin_turul_id:String!, $ajiliin_turul_id:String!, $aimag_niislel:String!, $sum_duureg:String!,){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
  groupFilters: [
        {
            combine: or
            filters: [
                  { column: "b_id", condition: equals, value: $brigad_id },
                { column: "tsag_torol_id", condition: equals, value: $ts_turul_id },
                { column: "tsalin_torol_id", condition: equals, value: $tsalin_turul_id },
                { column: "ajil_torol_id", condition: equals, value: $ajiliin_turul_id },
                { column: "aimag_id", condition: equals, value: $aimag_niislel },
                { column: "sum_id", condition: equals, value: $sum_duureg },
            ]
        }
      ]
  )
  {
    page
    total
    last_page
  ds_brigad_ajil_zar_view {
    aimag_id
    aimagname
    ajil
    ajil_hemjee
    ajil_torol_id
    ajiliin_torol
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    logo
    ner_mnbrigad
    e_ognoo
    id
    nemelt
    sanalbolgoh_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_torol_id
    tsagiin_torol
    tsalin_torol
    tsalin_torol_id
    uureg
    torol_gishuun
    torol_gishuun_bus
  }
   }
}
`;


// AJILIIN ZAR END

// AJILIIN BAIRNII ZAR START

export const GET_AJIL_BAIR_ZAR_VIEW = gql`{
  ds_ajil_bair_zar_view {
    aimag_id
    aimagname
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    hemjee
    id
    ajiliin_torol
    logo
    mergejil
    mergejil_tuvshin
    mergejil_tuvshin_id
    nemelt
    nemelt_uralshuulal
    ner_mnbaiguullaga
    sanal_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_id
    tsagiin_torol
    tsag_id
    tsalin_torol
    undsen_uureg
    gishuun
    gishuun_bus
  }
}
`;
export const GET_AJIL_BAIR_ZAR_VIEW_LIMIT5 = gql`{
  ds_ajil_bair_zar_view(limit:5, sorts: [{ column: "id", order: desc }]) {
    aimag_id
    aimagname
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    hemjee
    id
    ajiliin_torol
    logo
    mergejil
    mergejil_tuvshin
    mergejil_tuvshin_id
    nemelt
    nemelt_uralshuulal
    ner_mnbaiguullaga
    sanal_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_id
    tsagiin_torol
    tsag_id
    tsalin_torol
    undsen_uureg
    gishuun
    gishuun_bus
  }
}
`;
export const GET_BRGD_AJLIIN_BAIR_ZAR_VIEW = gql`{
  ds_brigad_ajilin_bair_zar_view {
  aimag_id aimagname 
  ajil_torol_id 
  ajiliin_torol 
  bag_id bagname 
  brigad_id 
  brigad_ner 
  created_at 
  d_ognoo 
  e_ognoo 
  hemjee 
  id
  logo
  mergejil_tuvshin 
  mergejil_tuvshin_id 
  nemelt_medeelel 
  nemelt_uramshuulal 
  shaardlaga tsag_id 
  tsagiin_torol 
  tsalin_id 
  tsalin_sanal 
  tsalin_torol 
  updated_at uureg 
  gishuun
  gishuun_bus
  }
}
`;
export const GET_BRGD_AJLIIN_BAIR_ZAR_VIEW_LIMIT5 = gql`{
  ds_brigad_ajilin_bair_zar_view(limit:5, sorts: [{ column: "id", order: desc }]) {
  aimag_id aimagname 
  ajil_torol_id 
  ajiliin_torol 
  bag_id bagname 
  brigad_id 
  brigad_ner 
  created_at 
  d_ognoo 
  e_ognoo 
  hemjee 
  id
  logo
  mergejil
  mergejil_tuvshin 
  mergejil_tuvshin_id 
  nemelt_medeelel 
  nemelt_uramshuulal 
  shaardlaga tsag_id 
  tsagiin_torol 
  tsalin_id 
  tsalin_sanal 
  tsalin_torol 
  updated_at uureg 
  gishuun
  gishuun_bus
  }
}
`;

export const GET_AJIL_BAIR_ZAR_VIEW_DETAILS = gql`
query ds_ajil_bair_zar_view($z_id: String!){
  ds_ajil_bair_zar_view(
    filters: [{ column: "id", condition: equals, value: $z_id }]
  ){
    aimag_id
    aimagname
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    hemjee
    id
    ajiliin_torol
    logo
    mergejil
    mergejil_tuvshin
    mergejil_tuvshin_id
    nemelt
    nemelt_uralshuulal
    ner_mnbaiguullaga
    sanal_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_id
    tsagiin_torol
    tsag_id
    tsalin_torol
    undsen_uureg
    phone_one
    phone_two
    page
    mail
    bair_toot
    twitter
    linkedin
    facebook
    gudamj
  }
}
`;
export const GET_BRGD_AJLIIN_BAIR_ZAR_VIEW_DETAILS = gql`
query ds_brigad_ajilin_bair_zar_view($z_id: String!){
  ds_brigad_ajilin_bair_zar_view(
    filters: [{ column: "id", condition: equals, value: $z_id }]
  ){
  mergejil
  aimag_id 
  aimagname 
  ajil_torol_id 
  ajiliin_torol 
  bag_id 
  bagname 
  brigad_id 
  brigad_ner 
  created_at 
  d_ognoo 
  e_ognoo 
  logo
  id
  hemjee 
  mergejil_tuvshin 
  mergejil_tuvshin_id 
  nemelt_medeelel 
  nemelt_uramshuulal 
  shaardlaga 
  tsag_id 
  tsagiin_torol 
  tsalin_id 
  tsalin_sanal 
  tsalin_torol 
  updated_at 
  uureg 
  sumname
  mail
  face
  twitter
  utas_one
  utas_two
  web
  gudam
  toot
  linked_in
  }
}
`;

export const GET_BRGD_AJIL_BAIR_ZAR_PAGE = gql`
query paginate($page:Int!, $size:Int!){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
  )
  {
    page
    total
    last_page
    ds_brigad_ajilin_bair_zar_view {
  aimag_id 
  aimagname 
  ajil_torol_id 
  ajiliin_torol 
  bag_id 
  bagname 
  brigad_id 
  brigad_ner 
  created_at 
  d_ognoo 
  e_ognoo 
  logo
  hemjee 
  mergejil
  mergejil_tuvshin 
  mergejil_tuvshin_id 
  nemelt_medeelel 
  nemelt_uramshuulal 
  shaardlaga tsag_id 
  id
  tsagiin_torol 
  tsalin_id 
  tsalin_sanal 
  tsalin_torol 
  updated_at 
  uureg 
    gishuun
  gishuun_bus
    }
   }
}
`;
export const GET_AJIL_BAIR_ZAR_PAGE = gql`
query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size,)
  {
    page
    total
    last_page
    ds_ajil_bair_zar_view {
    aimag_id
    aimagname
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    hemjee
    logo
    id
    ajiliin_torol
    mergejil_tuvshin
    mergejil_tuvshin_id
    nemelt
    mergejil
    nemelt_uralshuulal
    ner_mnbaiguullaga
    sanal_tsalin
    shaardlaga
    sum_id
    sumname
    tsag_id
    tsagiin_torol
    tsag_id
    tsalin_torol
    undsen_uureg
    gishuun_bus
    gishuun
     tsalin_id
    }
   }
}
`;

export const GET_AJIL_BAIR_ZAR_PAGE_BY_ID = gql`
query paginate($page:Int!, $size:Int!, $baiguullaga_id:String!, $ts_turul_id: String!, $tsalin_turul_id: String!, $mergejil_id: String!, $aimag_niislel: String!, $sum_duureg: String!){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
        groupFilters: [
        {
            combine: or
            filters: [
                { column: "b_id", condition: equals, value: $baiguullaga_id },
                { column: "tsag_id", condition: equals, value: $ts_turul_id },
                { column: "tsalin_id", condition: equals, value: $tsalin_turul_id },
                { column: "ajil_bair_id_mergejil", condition: equals, value: $mergejil_id },
                { column: "aimag_id", condition: equals, value: $aimag_niislel },
                { column: "sum_id", condition: equals, value: $sum_duureg },
            ]
        }
      ]
    )
  {
    page
    total
    last_page
    ds_ajil_bair_zar_view {
    aimag_id
    aimagname
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    hemjee
    id
    logo
    id_mergejil
    ajiliin_torol
    mergejil_tuvshin
    mergejil_tuvshin_id
    nemelt
    nemelt_uralshuulal
    ner_mnbaiguullaga
    sanal_tsalin
    shaardlaga
    sum_id
    mergejil
    sumname
    tsag_id
    tsagiin_torol
    tsalin_torol
    undsen_uureg
    gishuun_bus
    gishuun
    tsalin_id
    ajil_bair_id_mergejil
      
      }
   }
}
`;
export const GET_BRGD_AJIL_BAIR_ZAR_PAGE_BY_ID = gql`
query paginate($page:Int!, $size:Int!, $brigad_id: String!, $tsag_id: String!, $tsalin_id: String!, $mergejil_id: String!, $aimag_niislel: String!, $sum_duureg: String!,){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
        groupFilters: [
        {
            combine: or
            filters: [
                { column: "brigad_id", condition: equals, value: $brigad_id },
                { column: "tsag_id", condition: equals, value: $tsag_id },
                { column: "tsalin_id", condition: equals, value: $tsalin_id },
                { column: "ajil_id_mergejil", condition: equals, value: $mergejil_id },
                { column: "aimag_id", condition: equals, value: $aimag_niislel },
                { column: "sum_id", condition: equals, value: $sum_duureg },
            ]
        }
      ]
    )
  {
    page
    total
    last_page
    ds_brigad_ajilin_bair_zar_view {
    aimag_id aimagname 
    ajil_torol_id 
    ajiliin_torol 
    bag_id bagname 
    brigad_id 
    brigad_ner 
    created_at 
    d_ognoo 
    e_ognoo 
    hemjee 
    logo
    id
    mergejil
    idmergejil
    mergejil_tuvshin 
    mergejil_tuvshin_id 
    nemelt_medeelel 
    nemelt_uramshuulal 
    shaardlaga 
    tsag_id 
    tsagiin_torol 
    tsalin_id 
    tsalin_sanal 
    tsalin_torol 
    updated_at 
    uureg 
    gishuun
    gishuun_bus
    ajil_id_mergejil
      }
   }
}
`;

//BAIGUULGIIN AJILIIN ZAR END
export const GET_BAIGUULLAGA_AJLIIN_ZAR_PAGINATE = gql`
query paginate($page:Int!, $size:Int!, $ba_id: String!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size, filters: [{ column: "b_id", condition: equals, value: $ba_id}])
  {
    page
    total
    last_page
    ds_ajiliin_zar_view {
        aimag_id
        aimagname
        ajil
        ajil_hemjee
        ajil_torol_id
        ajiliin_torol
        b_id
        bag_id
        bagname
        created_at
        d_ognoo
        deleted_at
        e_ognoo
        id
        nemelt
        ner_mnbaiguullaga
        sanalbolgoh_tsalin
        sum_id
        sumname
        tsag_torol_id
        tsagiin_torol
        tsalin_torol_id
        updated_at
    }
   }
}
`;


//BRIGAD AJILIIN ZAR END
export const GET_BRIGAD_AJLIIN_ZAR_PAGINATE = gql`
query paginate($page:Int!, $size:Int!, $id: String!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size, filters: [{ column: "b_id", condition: equals, value: $id}])
  {
    page
    total
    last_page
    ds_brigad_ajil_zar_view {
        aimagname
        ajil
        ajil_hemjee
        ajiliin_torol
        b_id
        d_ognoo
        e_ognoo
        tsagiin_torol
        b_id
        bagname
        id
        logo
        ner_mnbrigad
        torol_gishuun
        torol_gishuun_bus
    }
   }
}
`;

//Baiguullaga ajliin zar - right column
export const GET_BAIGUULLAGA_AJLIIN_ZAR = gql`
{
    ds_ajiliin_zar_view(sorts: [{ column: "id", order: desc }], limit: 20) {
        aimag_id
        aimagname
        ajil
        ajil_hemjee
        ajil_torol_id
        ajiliin_torol
        b_id
        bag_id
        bagname
        created_at
        d_ognoo
        deleted_at
        e_ognoo
        id
        nemelt
        ner_mnbaiguullaga
        sanalbolgoh_tsalin
        sum_id
        sumname
        tsag_torol_id
        tsagiin_torol
        tsalin_torol_id
        updated_at
    }
}
`;

//Brigad ajliin zar - right column
export const GET_BRIGAD_AJLIIN_ZAR = gql`
{
    ds_brigad_ajil_zar_view(sorts: [{ column: "id", order: desc }], limit: 20) {
        aimagname
        ajiliin_torol
        e_ognoo
        id
        ner_mnbrigad
    }
}
`;


// baiguulgiin zarlasan ajliin bairnii zar
export const GET_BAIGUULLAGA_AJLIIN_BAIR_ZAR_PAGINATE = gql`
query paginate($page:Int!, $size:Int!, $ba_id: String!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size, filters: [{ column: "b_id", condition: equals, value: $ba_id}])
  {
    page
    total
    last_page
    ds_ajil_bair_zar_view {
        acc_check
        aimag_id
        aimagname
        ajil_torol_id
        ajiliin_torol
        b_id
        bag_id
        bagname
        e_ognoo
        d_ognoo
        id
        logo
        tsag_id
        tsagiin_torol
        gishuun
        gishuun_bus
        mergejil
    }
   }
}
`;
// baiguulgiin zarlasan ajliin bairnii zar
export const GET_BRIGAD_AJLIIN_BAIR_ZAR_PAGINATE = gql`
query paginate($page:Int!, $size:Int!, $id: String!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size, filters: [{ column: "brigad_id", condition: equals, value: $id}])
  {
    page
    total
    last_page
    ds_brigad_ajilin_bair_zar_view {
      aimagname
      brigad_ner
      id
      ajiliin_torol
      tsagiin_torol
      e_ognoo
      d_ognoo
      brigad_id
      mergejil
    }
   }
}
`;
// baiguulgiin taniltsuulga - right column ajliin bairnii zar
export const GET_BAIGUULLAGA_AJLIIN_BAIR_ZAR = gql`{
    ds_ajil_bair_zar_view(sorts: [{ column: "id", order: desc }], limit: 20) {
        acc_check
        aimag_id
        aimagname
        ajil_torol_id
        ajiliin_torol
        b_id
        bag_id
        bagname
        e_ognoo
        d_ognoo
        id
        logo
        tsag_id
        tsagiin_torol
        gishuun
        gishuun_bus
        ner_mnbaiguullaga
    }
}
`;

// brigadiin taniltsuulga - right column ajliin bairnii zar
export const GET_BRIGAD_AJLIIN_BAIR_ZAR_RIGHT_COL = gql`{
    ds_brigad_ajilin_bair_zar_view(sorts: [{ column: "id", order: desc }], limit: 20) {
        aimagname
        id
        gishuun
        gishuun_bus
        e_ognoo
        ajiliin_torol
        brigad_ner
    }
}
`;


export const GET_BAIGUULLAGA_AJLIIN_ZAR_VIEW = gql`
query ds_ajiliin_zar_view($ba_id: String!){
  ds_ajiliin_zar_view(
     filters: [{ column: "b_id", condition: equals, value: $ba_id}]
  ){
    aimag_id
    aimagname
    ajil
    ajil_hemjee
    ajil_torol_id
    ajiliin_torol
    b_id
    bag_id
    bagname
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    id
    nemelt
    ner_mnbaiguullaga
    sanalbolgoh_tsalin
    sum_id
    sumname
    tsag_torol_id
    tsagiin_torol
    tsalin_torol_id
    updated_at
  }
}
`;
export const GET_AJIL_BAIR_ZAR_LIST_BAIGUULLAGA = gql`
query ds_ajil_bair_zar_view($ba_id: String!){
  ds_ajil_bair_zar_view(
     filters: [{ column: "b_id", condition: equals, value: $ba_id}]
  ){
    aimag_id
    aimagname
    ajil_bair_id
    ajil_torol_id
    ajiliin_bair_ner
    ajiliin_torol
    b_id
    created_at
    d_ognoo
    e_ognoo
    id
    sum_id
    sumname
    tsag_id
    tsagiin_torol
    tsalin_torol
    undsen_uureg
    updated_at
  }
}
`;


export const GET_BRIGAD_VIEW = gql`{
  ds_brilgas_view {
    ahlagch_zurag
    aimag_id
    aimagname
    alban_tatvar_eseh
    bag_id
    bagname
    baiguullaga_ner
    banner
    borluulalt_orlogo

    e_ognoo
    face
    gudam
    huis
    id
    linked_in
    logo
    mail
    nd_toldog_eseh
    ner
    ner_en
    ner_mn
    noat_eseh
    on_jil_id
    ovog
    register
    sum_id
    sumname
    surguuli
    toot
    torol_gishuun
    torol_gishuun_bus
    tuhai
    twitter
    
    utas_one
    utas_two
    web
    ds_brigad_ajilin_bair_zar_view {
       ajiliin_torol
       id
    }
    ds_brigad_ajil_zar_view {
       ajiliin_torol
       id
    }
  }
}
`;
export const GET_BRIGAD_VIEW_LIMIT_10 = gql`{
  ds_brilgas_view(limit:10){
    ahlagch_zurag
    aimag_id
    aimagname
    alban_tatvar_eseh
    bag_id
    bagname
    baiguullaga_ner
    banner
    borluulalt_orlogo

    e_ognoo
    face
    gudam
    huis
    id
    linked_in
    logo
    mail
    nd_toldog_eseh
    ner
    ner_en
    ner_mn
    noat_eseh
    on_jil_id
    ovog
    register
    sum_id
    sumname
    surguuli
    toot
    torol_gishuun
    torol_gishuun_bus
    tuhai
    twitter
    
    utas_one
    utas_two
    web
    ds_brigad_ajilin_bair_zar_view {
       ajiliin_torol
       id
    }
    ds_brigad_ajil_zar_view {
       ajiliin_torol
       id
    }
  }
}
`;
export const GET_BRIGAD_VIEW_LIMIT_15 = gql`{
  ds_brilgas_view(limit:15){
    ahlagch_zurag
    aimag_id
    aimagname
    alban_tatvar_eseh
    bag_id
    bagname
    baiguullaga_ner
    banner
    borluulalt_orlogo

    e_ognoo
    face
    gudam
    huis
    id
    linked_in
    logo
    mail
    nd_toldog_eseh
    ner
    ner_en
    ner_mn
    noat_eseh
    on_jil_id
    ovog
    register
    sum_id
    sumname
    surguuli
    toot
    torol_gishuun
    torol_gishuun_bus
    tuhai
    twitter
    
    utas_one
    utas_two
    web
    ds_brigad_ajilin_bair_zar_view {
       ajiliin_torol
       id
    }
    ds_brigad_ajil_zar_view {
       ajiliin_torol
       id
    }
  }
}
`;

export const GET_BRIGAD_GISHUUD_VIEW = gql`{
  ds_brigad_gishuud_view {
    ajil_tolov_id
    brigad_id
    engineer_id
    erhlelt_tolov
    id
    ner_brigad
    ner_eta
    ovog_eta
    tailbar
    unelgee
    unelgee_id
  }
}
`;


export const GET_BRGD_SHAGNAL_URAMSHUULAL_VIEW = gql`{
  ds_brigad_shagnal_uramshuulal_view {
    b_id
    brigad_ner
    created_at
    deleted_at
    id
    ner
    ognoo
    ologson_baiguullaga
    updated_at
    zurag
  }
}
`;


export const GET_BRIGAD_TEKNIK_TT_VIEW = gql`{
  ds_brigad_tehnik_tt_view {
    b_id
    brigad_ner
    busad
    id
    model
    too
    tt
    uzuulelt
    zoriulalt
    zurag
  }
}
`;


export const GET_BRGD_GUITSETGESEN_AJIL_VIEW = gql`{
  ds_brigad_guitsetgesen_ajil_view {
    aimag_id
    aimagname
    ajil_guitsetgsen_id
    alban_tushaaltan
    br_id
    brigad_ner
    brigad_uil_ajilgaa_chiglelcol
    created_at
    d_ognoo
    deleted_at
    e_ognoo
    guitsetgsen_ajil
    habe_eseh
    id
    mail
    sum_id
    sumname
    toim
    updated_at
    utas
    zahialagch
    zurag
  }
}
`;


export const GET_MEDEE_ANGILAL_VIEW = gql`{
  ds_medee_angilal_view {
    id
    medee_angilal
    medee_angilal_en
  }
}
`;


export const GET_MEDEE_VIEW = gql`{
  ds_medee_view {
    angilal_id
    created_at
    deleted_at
    garchig_en
    garchig_mn
    handalt_to
    huvaaltsah
    id
    link
    medee_angilal
    medee_en
    medee_mn
    updated_at
    zurag
  }
}
`;

export const GET_FQA_ANGILAL_VIEW = gql`{
  ds_fqa_angilal_view {
    asuul_torol
    asuul_torol_en
    id
  }
}
`;

export const GET_FQA_VIEW_BY_ID = gql`
query ds_fqa_view($cat_id: String!){
  ds_fqa_view(
    filters: [{ column: "angilal_id", condition: equals, value: $cat_id }]
  ){
    angilal_id
    asuul_torol
    asuult_em
    asuult_mn
    created_at
    deleted_at
    hariult_en
    hariult_mn
    id
    updated_at
    zurag
  }
}
`;

export const GET_FQA_VIDEOS_CATEGORY = gql`{
    lut_helper_video_cat{
        cat_video
        id
    }
}
`;export const GET_FQA_VIDEO_BY_ID = gql`
query ds_helper_video($cat_id: String!){
  ds_helper_video(
    filters: [{ column: "cat_id", condition: equals, value: $cat_id }]
  ){
    id
    cat_id
    cat_video
    title_eng
    title_mn
    video
  }
}
`;
// AJIL OLGOGCH TULUV
export const AJIL_OLGOGCH_TULUV = gql`{
  ds_ajil_olgohch_tolov_view{
    id
    tolov
  }
}
`;
// GET_UIL_AJIGAA_CHIGLEL BAIGUULLAGA
export const GET_UIL_AJIGAA_CHIGLEL = gql`{
  ds_uil_ajilgaa_view{
    id
    t_id
    uilajilgaa
  }
}
`;
// GET_UIL_AJIGAA_CHIGLEL BRIGAD
export const GET_UIL_AJIGAA_CHIGLEL_BRIGAD = gql`{
  lut_brigad_uil_ajilgaa_chiglel{
      brigad_id
      brigad_uil_ajilgaa_chiglelcol
      id
  }
}
`;
// AIMAG_NIISLEL
export const GET_AIMAG_NIISLEL = gql`{
  ds_aimag_view{
      aimagcode
      aimagname
      aimagname_mgl
      aimagzipcode
      id
      location
      zoneid
  }
}
`;


export const GET_SUM_DUUREG_BY_AIMAG = gql`
query ds_sum_view($aimagid: String!){
  ds_sum_view(
    filters: [{ column: "aimagid", condition: equals, value: $aimagid }]
  ){
      aimagid
      aimagname
      hurgelt
      id
      sumcode
      sumname
      sumzipcode
  }
}
`;
export const GET_SUM_DUUREG_ALL = gql`{
  ds_sum_view{
      aimagid
      aimagname
      hurgelt
      id
      sumcode
      sumname
      sumzipcode
  }
}
`;
// filter xiix jishee
export const GET_JISHEE = gql`
query ds_durem_juram_view($question_category_id: String!){
  ds_durem_juram_view(
    filters: [{ column: "id", condition: equals, value: "1" }]
  ){
    angilal_id
    created_at
    deleted_at
    durem_env
    durem_mn
    file
    id
    link
    updated_at
  }
}
`;


// MEDEE MEDEELEL
export const GET_MEDEE_ANGILAL = gql`{
  ds_medee_angilal_view {
    id
    medee_angilal
    medee_angilal_en
  }
}
`;

export const GET_NEWS_BY_CATEGORY_ID = gql`
query ds_medee_view($cat_id: String!){
  ds_medee_view(
    filters: [{ column: "angilal_id", condition: equals, value: $cat_id }]
  ){
    angilal_id
    created_at
    deleted_at
    garchig_en
    garchig_mn
    handalt_to
    huvaaltsah
    medee_angilal
    medee_mn
    medee_en
    updated_at
    zurag
    id
  }
}
`;
export const GET_NEWS_BY_ID_DETAILS = gql`
query ds_medee_view($news_id: String!){
  ds_medee_view(
    filters: [{ column: "id", condition: equals, value: $news_id }]
  ){
    angilal_id
    zurag
    garchig_mn
    garchig_en
    medee_mn
    medee_en
    updated_at
    id
  }
}
`;


export const GET_NEWS = gql`
query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size)
  {
    page
    total
    last_page
    ds_medee_view {
      angilal_id
      zurag
      garchig_mn
      garchig_en
      medee_mn
      medee_en
      medee_angilal
      medee_angilal_en
      updated_at
      id
    }
   }
}
`;

export const GET_NEWS_BY_ID = gql`
query paginate($page:Int!, $size:Int!, $cat_id: String!){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
  filters: [{ column: "angilal_id", condition: equals, value: $cat_id }]
  )
  {
    page
    total
    last_page
    ds_medee_view {
      angilal_id
      zurag
      garchig_mn
      garchig_en
      medee_mn
      medee_en
      medee_angilal
      medee_angilal_en
      updated_at
      id
    }
   }
}
`;

// ZUWULGUU MEDEE MEDEELEL START

export const GET_ZUWULGUU_MEDEE_ANGILAL = gql`{
  ds_zovolgoo_angilal_en_view {
    id
    z_angilal_en
    z_angilal_mn
  }
}
`;
export const GET_ZUWULGUU_MEDEE_BY_CATEGORY_ID = gql`
query ds_zov_medeelel_view($cat_id: String!){
  ds_zov_medeelel_view(
    filters: [{ column: "zovolgoo_angilal_id", condition: equals, value: $cat_id }]
  ){
    created_at
    handalt_too
    huvaaltsah
    id
    link
    z_angilal_mn
    zovolgoo_angilal_id
    zovolgoo_en
    zovolgoo_medee_en
    zovolgoo_medee_mn
    zovolgoo_mn
    zurag
  }
}
`;
export const GET_ZUWULGUU_MEDEE_PAGE_BY_ID = gql`
query paginate($page:Int!, $size:Int!, $cat_id: String!){
  paginate(
  sorts: [{ column: "id", order: desc }], page: $page, size: $size,
  filters: [{ column: "zovolgoo_angilal_id", condition: equals, value: $cat_id }]
  )
  {
    page
    total
    last_page
    ds_zov_medeelel_view {
      created_at
      handalt_too
      huvaaltsah
      id
      link
      z_angilal_mn
      zovolgoo_angilal_id
      zovolgoo_en
      zovolgoo_medee_en
      zovolgoo_medee_mn
      zovolgoo_mn
      zurag
    }
   }
}
`;
export const GET_ZUWULGUU_MEDEE_PAGE = gql`
query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "id", order: desc }], page: $page, size: $size)
  {
    page
    total
    last_page
    ds_zov_medeelel_view {
      created_at
      handalt_too
      huvaaltsah
      id
      link
      z_angilal_mn
      zovolgoo_angilal_id
      zovolgoo_en
      zovolgoo_medee_en
      zovolgoo_medee_mn
      zovolgoo_mn
      zurag
    }
   }
}
`;
export const GET_ZUWULGUU_MEDEE_BY_ID_DETAILS = gql`
query ds_zov_medeelel_view($news_id: String!){
  ds_zov_medeelel_view(
    filters: [{ column: "id", condition: equals, value: $news_id }]
  ){
      created_at
      handalt_too
      huvaaltsah
      id
      link
      z_angilal_mn
      zovolgoo_angilal_id
      zovolgoo_en
      zovolgoo_medee_en
      zovolgoo_medee_mn
      zovolgoo_mn
      zurag
  }
}
`;
export const GET_ZUWULGUU_MEDEE_ALL = gql`{
  ds_zov_medeelel_view {
    created_at
    handalt_too
    huvaaltsah
    id
    link
    z_angilal_mn
    zovolgoo_angilal_id
    zovolgoo_en
    zovolgoo_medee_en
    zovolgoo_medee_mn
    zovolgoo_mn
    zurag
  }
}
`;
// ZUWULGUU MEDEE MEDEELEL END
export const GET_NEWS_BY_ID_DETAILS_LIST = gql`{
  ds_medee_view {
    id
    angilal_id
    zurag
    garchig_mn
    garchig_en
    updated_at
  }
}
`;


export const GET_CONTACT_US = gql`{
  ds_holboo_barih_view {
    id
    ner_en
    ner_mn
    hayg_en
    hayg_mn
    twitter
    facebook
    linkedin
    tuhai
    phone_one
    phone_two
    mail
    video
  }
}
`;


//Хамтрагч байгууллага
export const GET_HAMTRAGCH_BAIGUULLAGUUD = gql`{
  ds_co_operation_new_from_table {
      id
      ner
      logo
      link
  }
}
`;

//Үйлчилгээний нөхцөл
export const GET_UILCHILGEENII_NUKHTSUL_ANGILAL_VIEW = gql`{
  ds_uilchilgee_angilal_view {
    id
    term_en
    term_mn
  }
}
`;

//Үйлчилгээний нөхцөл
export const GET_UILCHILGEENII_NUKHTSUL_VIEW_BY_ID = gql`
query ds_uilchilgeenii_nohtsol_view($cat_id: String!){
  ds_uilchilgeenii_nohtsol_view(
    filters: [{ column: "id_nohtsol", condition: equals, value: $cat_id }]
  ){
    id
    id_nohtsol
    id_buleg
    term_en
    term_mn
    deleted_at
    nohtsol_en
    nohtsol_mn
    zaalt
    zaalt_en
  }
}
`;

//Гишүүн багц
export const GET_GISHUUN_BAGTS_ANGILAL_VIEW = gql`{
  ds_bagts_view {
    id
    ajil_zar_id
    torol_id
    torol
    torol_en
    bagts_ner
    gishuun_jil
    ajiliin_Zar
    ajiliin_zar_en
    anket
    busad
    bagts_unelgee
    created_at
    deleted_at
    updated_at
    icon
    color
   
  }
}
`;

//Гишүүн бус багц
export const GET_GISHUUN_BUS_BAGTS_ANGILAL_VIEW = gql`{
  ds_bagts_gishuun_bus_view {
    id
    torol
    torol_id
    gishuun_jil
    sar
    ajli_zar_jil
    ajil_zar_sar
    anket
    busad
    bagts_une_jil
    bagts_une_sar
    uilchilgee_hugatsaa_id
    uilchilgee_sar
    une_gurav
    une_hoyor
    une_neg
    zar_gurav
    zar_hoyor
    zar_neg
    ongo
  }
}
`;

//Төлбөртэй banner
export const GET_BANNER_VIEW = gql`{
  ds_banner_view {
    id
    banner
    unelgee
    link
  }
}
`;

//video
// export const GET_VIDEO_VIEW = gql`{
//   ds_banner_view {
//     id
//     video
//   }
// }
// `;


export const GET_MERGEJIL_DED_SALBAR = gql`{
  ds_mergejil_ded_salbar_view {
    id
    ded_salbar
  }
}
`;
export const GET_MERGEJIL = gql`{
  ds_mergejil_lut_view {
    id
    mergejil
    mergejil_ded_id
  }
}
`;
export const GET_TSAGIIN_TURUL = gql`{
  ds_tsag_view {
    id
    tsagiin_torol
  }
}
`;
export const GET_TSALIN_TURUL = gql`{
  ds_tsalin_view {
    id
    tsalin_torol
  }
}
`;
export const GET_AJILIIN_TURUL = gql`{
  lut_ajiliin_torol {
    id
    ajiliin_torol
  }
}
`;
//ajilzar nemelt API
export const GET_BRGD_AJILIIN_ZAR_VIEW_DETAILS_AJILTAN_TOO = gql`
query ds_brigad_ajil_zar_view($b_id: String!){
  ds_brigad_ajil_zar_view(
    filters: [{ column: "id", condition: equals, value: $b_id }]
  ){
    id
    ajitan_teknik
    toos
  }
}
`;
export const GET_AJILIIN_ZAR_VIEW_DETAILS_AJILTAN_TOO = gql`
query ds_ajiliin_zar_view($z_id: String!){
  ds_ajiliin_zar_view(
    filters: [{ column: "id", condition: equals, value: $z_id }]
  ){
    id
    mergejil_teknik_ajiltan
    too_teknik_ajiltan
  }
}
`;

// ZAR HADGALAH START
export const POST_SAVE_BAIGUULAGA_AJIL_BAIR_ZAR = gql`
    mutation createSaveZarBaiguullagaAjilBairZar($user_id: Int!, $zar_id: Int!){
        createSaveZarBaiguullagaAjilBairZar(input:{zar_id:$zar_id, user_id:$user_id}){
            user_id
            zar_id
            id
        }
    }
`;
export const POST_SAVE_BRIGAD_AJIL_BAIR_ZAR = gql`
    mutation createSaveZarBrigadAjilBairZar($user_id: Int!, $zar_id: Int!){
        createSaveZarBrigadAjilBairZar(input:{zar_id:$zar_id, user_id:$user_id}){
            user_id
            zar_id
            id
        }
    }
`;
export const POST_SAVE_BAIGUULAGA_AJIL_ZAR = gql`
    mutation createSaveZarBaiguullagaAjilZar($user_id: Int!, $zar_id: Int!){
        createSaveZarBaiguullagaAjilZar(input:{zar_id:$zar_id, user_id:$user_id}){
            user_id
            zar_id
            id
        }
    }
`;
export const POST_SAVE_BRIGAD_AJIL_ZAR = gql`
    mutation createSaveZarBrigadAjilZar($user_id: Int!, $zar_id: Int!){
        createSaveZarBrigadAjilZar(input:{zar_id:$zar_id, user_id:$user_id}){
            user_id
            zar_id
            id
        }
    }
`;
// ZAR HADGALAH END

// SAVED ZAR START
export const GET_SAVE_BAIGUULLAGA_AJIL_BAIR_ZAR = gql`
 query ds_save_zar_baiguullaga_ajil_bair_zar($user_id: String!){
  ds_save_zar_baiguullaga_ajil_bair_zar(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ){
    aimagname
    ajiliin_bair_ner
    baiguullaga_ner
    duusah_ognoo
    id
    niitelsen_ognoo
    tsagiin_torol
    user_id
    zar_id
  }
}
`;
export const GET_SAVE_BRIGAD_AJIL_BAIR_ZAR = gql`
 query ds_save_zar_brigad_ajil_bair_zar($user_id: String!){
  ds_save_zar_brigad_ajil_bair_zar(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ){
    aimagname
    ajiliin_bair
    brigad_ner
    duusah_ognoo
    id
    niitelsen_ognoo
    tsagiin_torol
    user_id
    zar_id
  }
}
`;
export const GET_SAVE_BAIGUULLAGA_AJIL_ZAR = gql`
 query ds_save_zar_baiguullaga_ajil_zar($user_id: String!){
  ds_save_zar_baiguullaga_ajil_zar(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ){
    aimagname
    baiguullaga_ner
    guitsetgeh_ajil
    niitelsen_ognoo
    id
    sanal_avch_d_ognoo
    tsagiin_torol
    user_id
    zar_id
  }
}
`;
export const GET_SAVE_BRIGAD_AJIL_ZAR = gql`
 query ds_save_zar_brigad_ajil_zar($user_id: String!){
  ds_save_zar_brigad_ajil_zar(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ){
    aimagname
    ajiliin_bair
    brigad_ner
    duusah_ognoo
    id
    niitelsen_ognoo
    tsagiin_torol
    user_id
    zar_id
  }
}
`;
// SAVED ZAR END

// ZAR DELETE START
export const POST_DELETE_BAIGUULAGA_AJIL_BAIR_ZAR = gql`
    mutation deleteSaveZarBaiguullagaAjilBairZar($id: ID!){
        deleteSaveZarBaiguullagaAjilBairZar(id: $id){
            id
        }
    }
`;
export const POST_DELETE_BRIGAD_AJIL_BAIR_ZAR = gql`
    mutation deleteSaveZarBrigadAjilBairZar($id: ID!){
        deleteSaveZarBrigadAjilBairZar(id:$id,){
            id
        }
    }
`;
export const POST_DELETE_BAIGUULAGA_AJIL_ZAR = gql`
    mutation deleteSaveZarBaiguullagaAjilZar($id: ID!){
        deleteSaveZarBaiguullagaAjilZar(id:$id){
            id
        }
    }
`;
export const POST_DELETE_BRIGAD_AJIL_ZAR = gql`
    mutation deleteSaveZarBrigadAjilZar($id: ID!){
        deleteSaveZarBrigadAjilZar(id:$id,){
            id
        }
    }
`;
// ZAR DELETE END

// SAVE COMPANY,BRIGAD START
export const POST_SAVE_BAIGUULLAGA = gql`
    mutation createBaiguullagaDagah($user_id: Int!, $b_id: Int!){
        createBaiguullagaDagah(input:{user_id:$user_id, baiguullaga_id:$b_id}){
            user_id
            baiguullaga_id
            id
        }
    }
`;
export const POST_SAVE_BRIGAD = gql`
    mutation createBrigadDagah($user_id: Int!, $brigad_id: Int!){
        createBrigadDagah(input:{user_id:$user_id, brigad_id:$brigad_id}){
            user_id
            brigad_id
            id
        }
    }
`;
// SAVE COMPANY,BRIGAD END

// SAVED BAIGUULLAGA,BRIGAD START
export const GET_SAVE_BAIGUULLAGA = gql`
 query ds_baiguullaga_dagah_view($user_id: String!){
  ds_baiguullaga_dagah_view(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ){
    baiguullaga_id
    baiguullaga_ner
    id
    logo
    user_id
  }
}
`;
export const GET_SAVE_BRIGAD = gql`
 query ds_brigad_dagah_view($user_id: String!){
  ds_brigad_dagah_view(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ){
    brigad_id
    id
    logo
    ner_mn
    user_id
  }
}
`;
// SAVED BAIGUULLAGA,BRIGAD END

// ZAR DELETE START
export const POST_DELETE_BAIGUULAGA = gql`
    mutation deleteBaiguullagaDagah($baiguullaga_id: ID!){
        deleteBaiguullagaDagah(id:$baiguullaga_id,){
            id
        }
    }
`;
export const POST_DELETE_BRIGAD = gql`
    mutation deleteBrigadDagah($brigad_id: ID!){
        deleteBrigadDagah(id:$brigad_id,){
            id
        }
    }
`;
// ZAR DELETE END

//baiguulgiin vnelgee
export const GET_BAIGUULLAGIIN_UNELGEE = gql`
 query ds_unelgee_baiguullaga_view($id: String!){
  ds_unelgee_baiguullaga_view(
    filters: [{ column: "b_id", condition: equals, value: $id }]
  ){
    uzuulelt_id
    unelgee_b_id
    b_id
    b_unelgee
    id
    ner_mn
    tailbar
    unelgee_bb
  }
}
`;
//baiguulgiin vnelgee
export const GET_BAIGUULLAGIIN_GUITSETGEL_UNELGEE = gql`{
  ds_guitsetgesen_ajil_view
  {
    id
    ds_sub_baiguullaga_uneleh_veiw{
        ajil_id
        b_unelgee
        baiguullaga_id
        id
        ner_baiguullaga
        tailbar
        unelgee
        unelgee_id
        uzuulelt_id   
    }
  }
}
`;

//brigadiin unelgee vnelgee
export const GET_BRIGADIIN_UNELGEE = gql`
 query ds_unelgee_brigad_view($id: String!){
  ds_unelgee_brigad_view(
    filters: [{ column: "bri_id", condition: equals, value: $id }]
  ){
    uzuulelt_id
    b_unelgee
    bri_id
    id
    ner_mn
    tailbar
    unelgee_bb
    unelgee_bri_id
  }
}
`;
//brigadiin guitsetgeliin vnelgee
export const GET_BRIGAD_GUITSETGEL_UNELGEE = gql`{
    ds_brigad_guitsetgesen_ajil_view
    {
      ds_sub_brigad_uneleh_veiw{
      uzuulelt_id
      b_unelgee
      bri_id
      brigad_id
      hangamj_id
      id
      ner_brigad
      tailbar
      unelgee
    }
    }
}
`;

export const POST_FEEDBACK = gql`
    mutation createSanalHuselt($name: String!, $mail: String!, $desc: String!){
        createSanalHuselt(input:{ner:$name,  hayg:$mail, sanal:$desc, hariu_eseh:1, zurag: "", hariu: "",  shiidsen_tolov_id: 1}){
            hariu
            hariu_eseh
            hayg
            ner
            sanal
            shiidsen_tolov_id
            zurag
        }
    }
`;
