<template>
    <div>
        <div id="header">
            <!--<div class="bg">-->
            <!--<img src="../assets/ub.png" alt="">-->
            <!--</div>-->
            <div class="head_top d-none d-xl-block d-lg-block d-md-block">
                <div class="sub-header clearfix">
                    <div class="container">
                        <div class="wrapper">
                            <ul>
                                <li>
                                    <router-link to="/uilchilgeenii_nuhtsul">
                                        {{ 'uilch_nuhtsul' | translate }}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/help">{{ 'help_v' | translate }}</router-link>
                                </li>
<!--                                <li><a href="#footer_container">{{ 'holboo_barih' | translate }}</a></li>-->
                                <li v-if="userToken==null || userToken== 'null' || userToken==false"><a
                                        @click="loginRegisterShow = true">{{ 'loginreg' | translate }}</a></li>
                                <li v-for="locale in languages" :key="locale.index"
                                    v-show="locale.short != language.short" @click="changeLocale(locale)">
                                    <a>{{ locale.long }}</a></li>
                            </ul>
                            <div class="socials">
                                <a href="https://twitter.com/mnca.mn" target="_blank">
                                    <i class="ivu-icon ivu-icon-logo-twitter"></i>
                                </a>
                                <a href="https://www.youtube.com/mnca.mn" target="_blank">
                                    <i class="ivu-icon ivu-icon-logo-youtube"></i>
                                </a>
                                <a href="https://www.facebook.com/mnca.mn" target="_blank">
                                    <i class="ivu-icon ivu-icon-logo-facebook"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <b-navbar type="light" class="navbar-light theme-nav " toggleable="lg">
                    <div class="container">
                        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
                        <b-navbar-brand class="navbar-brand">
                            <router-link to="/">
                                <img v-if="language.short == 'mn'" src="../assets/mnca_logo.jpg" alt="logo"
                                     class="h_logo" style="padding-top: 6px">
                                <img v-else src="../assets/logo_eng.png" alt="logo" class="h_logo"
                                     style="padding-top: 6px">
                            </router-link>
                        </b-navbar-brand>
                        <b-collapse class="default-nav" is-nav id="nav_collapse">
                            <b-navbar-nav class="navbar-nav ml-auto" id="mymenu">
                                <!--                <b-nav-item href="#ANN">{{ 'hamtr_baiguullaguud' | translate }}</b-nav-item>-->
                                <b-nav-item to="/p_news">{{ 'news' | translate }}</b-nav-item>
                                <b-nav-item to="/duremjuram">{{ 'dur_juram' | translate }}</b-nav-item>
                                <b-nav-item to="/advice_information_list">{{
                                        'advice_information' | translate
                                    }}
                                </b-nav-item>
                                <b-nav-item style="color: #2BB673!important;"
                                            href="http://dulaalga.mn/?fbclid=IwAR381Toc-ZeLPyuqknUevcXZh4uFpHIVEWwd1IlRJl35S2KTpFV4jPtOmw0"
                                            target="_blank">{{ 'dulaan_shiidel' | translate }}
                                </b-nav-item>
                                <b-nav-item>
                                    <div class="right_item">
                                        <div class="item paper-notification"
                                             v-if="userToken != null && userToken != false && user ">
                                            <notif-widget :user="user.id"
                                                          v-if="userToken != null && userToken != false && user"/>
                                            <a @click="savedItemsShow = true" type="primary"><p>
                                                {{ 'medegdel' | translate }}</p></a>
                                        </div>
                                        <div class="item paper-notification"
                                             v-if="userToken != null && userToken != false && user && user.role == '5'">
                                            <i class="ivu-icon ivu-icon-ios-filing" href="javascript:void(0)" @click="anketModal = true"></i>
                                            <a href="javascript:void(0)" @click="anketModal = true">
                                                <p>{{ 'anket_bugluh' | translate }}</p>
                                            </a>
                                        </div>

                                        <Dropdown v-if="userToken != null && userToken != false && user">
                                            <div class="item">
                                                <i class="ivu-icon ivu-icon-ios-contact"></i>
                                                <p>{{ user.first_name }}
                                                    <Icon id="iconDrow" type="ios-arrow-down"></Icon>
                                                </p>
                                            </div>
                                            <DropdownMenu slot="list">
                                                <DropdownItem v-if="user.role == '3' || user.role == 3">
                                                    <a class="drop_down_menus" href="javascript:void(0)"
                                                       @click="openUrl('https://job-mnca.mn/admin#/p/53313428-a990-611f-d3a3-834339744821/13f1e096-a0bf-94b7-e9bc-8275a7fa27b2')">
                                                        {{ 'baiguullagiin_udirdlaga' | translate }}</a>
                                                </DropdownItem>
                                                <DropdownItem v-if="user.role == '4' || user.role == 4">
                                                    <a class="drop_down_menus" href="javascript:void(0)"
                                                       @click="openUrl('https://job-mnca.mn/admin#/p/8438cac0-6106-0231-0cda-966b9961389a/7bd96cd1-f16f-b793-3aa9-6940f1983611')">
                                                        {{ 'brigad_udirdlaga' | translate }}</a>
                                                </DropdownItem>
                                                <DropdownItem v-if="user.role == '5' || user.role == 5">
                                                    <a class="drop_down_menus" href="javascript:void(0)"
                                                       @click="anketModal = true">{{ 'anket_bugluh' | translate }}</a>
                                                </DropdownItem>
                                                <DropdownItem divided>
                                                    <a class="drop_down_menus" href="javascript:void(0)"
                                                       @click="savedItemsShow = true"
                                                       type="primary">{{ 'hadgalsan_ajliin_bairnii_zar' | translate }}</a>
                                                </DropdownItem>
                                                <DropdownItem divided>
                                                    <a class="drop_down_menus" href="javascript:void(0)"
                                                       @click="savedItemsTwo = true"
                                                       type="primary">{{ 'hadgalsan_zahialgat_ajil' | translate }}</a>
                                                </DropdownItem>
                                                <DropdownItem divided>
                                                    <a class="drop_down_menus" href="javascript:void(0)"
                                                       @click="flowedShow = true" type="primary">{{ 'dagah' | translate
                                                        }}</a>
                                                </DropdownItem>
                                                <DropdownItem divided><a class="drop_down_menus"
                                                                         href="javascript:void(0)" @click="logout()">{{
                                                        'garah' | translate
                                                    }}</a>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </b-nav-item>
                            </b-navbar-nav>
                        </b-collapse>
                    </div>
                </b-navbar>
            </div>
            <div class="row">
                <div class="container">
                    <div class="head">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="left">
                                  <div class="nav-holder lg:block hidden menuItem hover:text-on_surface-high items-center space-x-8 pl-10">
                                    <nav class="main-menu">
                                      <ul class="no-list-style">
                                        <li>
                                          <router-link to="/ajliin_bairnii_zar" class="hover:text-on_surface-high">{{ 'ajliin_bairnii_zar' | translate }}</router-link>
                                        <li>
                                          <router-link to="/ajliin_zar" class="hover:text-on_surface-high">{{ 'zahialgat_ajil' | translate }}</router-link>
                                        </li>
                                        <li>
                                          <router-link to="/ajilolgogch" class="hover:text-on_surface-high">{{ 'Ajil_olgogch' | translate }}</router-link>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="right">
                                    <Input search enter-button :placeholder="'hailt' | translate"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal v-model="loginRegisterShow" :styles="{ index: '2'}" footer-hide>
            <div class="login_form">
                <div class="login_form_title">
                    {{ 'log-in' | translate }} / <span>{{ 'register' | translate }} </span>
                </div>
                <div class="main">
                    <Tabs value="name1" v-model="tab" closable>
                        <TabPane :label="'log-in1' | translate" name="login">
                            <Form ref="login" :model="loginForm" :rules="loginRule" v-if="tab == 'login'">
                                <FormItem prop="login" :label="'email' | translate">
                                    <Input type="text" v-model="loginForm.login" :placeholder="'email' | translate"
                                           @keyup.enter.native="handleSubmit('login')">
                                        <Icon type="ios-person-outline" slot="prepend"></Icon>
                                    </Input>
                                </FormItem>
                                <FormItem prop="password" :label="'pass' | translate">
                                    <Input type="password" v-model="loginForm.password"
                                           :placeholder="'pass' | translate"
                                           @keyup.enter.native="handleSubmit('login')">
                                        <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                    </Input>
                                    <a href="3" class="forgot">{{ 'forgot_pass' | translate }} ?</a>
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" class="login_btn" @click="login('login')">
                                        {{ 'log-in1' | translate }}
                                    </Button>
                                </FormItem>
                            </Form>
                            <Alert type="success" v-if="isSuccess" show-icon>
                                {{ 'amjilttai_nevtrelt' | translate }}
                            </Alert>
                            <Alert type="error" show-icon v-if="isError">
                                {{ 'netrehed_aldaa' | translate }}
                            </Alert>
                        </TabPane>
                        <TabPane :label="'register1' | translate" name="register">
                            <div class="anketForm" v-if="tab == 'register'">
                                <Checkbox v-model="checkTerm" class="checkService" @click="checkTerm = !checkTerm">
                                    {{ 'i_agree' | translate }}<a href="javascript:void(0)" @click="termModal = true" class="service">
                                    ({{ 'Get_acquainted_terms_of_service' | translate }})</a>
                                </Checkbox>
                                <dataform ref="register-form" v-if="loginRegisterShow" :public="true" schemaID="404"
                                          v-show="checkTerm" :onSuccess="onSuccess" :onError="onError"/>
                                <!--<div :onSuccess="onSuccess" :onError="onError" :class="`dataform-footer ${checkService == true ? 'active' : ''}`"></div>-->
                                <!--</dataform>-->
                                <!--<data-from ref="register-form" url="https://job-mnca.mn" schemaID="user_form" :onSuccess="onSuccess" />-->
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
            <div slot="footer">
            </div>
        </Modal>
        <Modal v-model="anketModal" :title="'ankit' | translate" width="900px;" footer-hide>
            <dataform ref="anketForm" v-if="anketModal" :editMode="anket_id >= 1" :onReady="readyAnketForm" schemaID="2"
                      :onSuccess="onSuccessAnket" :onError="onError"/>
        </Modal>

        <Modal v-model="termModal" title="Үйлчилгээний нөхцөлтэй танилцах" width="900px;" footer-hide>
            <div class="serviceModal">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                    <strong><span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>ЦАХИМ АЖЛЫН БАЙРНЫ СИСТЕМИЙН ХЭРЭГЛЭГЧДИЙН ЖУРАМ</span></strong>
                </p><br>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>Цахим ажлын байрны систем болох&nbsp;</span><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>нь дараах нөхцөлийн дагуу үйлчлэх ба тус системийн хэрэглэгчид нь энэхүү цахим ажлын байрны системийн хэрэглэгчийн нөхцөлийг баримтлан үйлчилнэ.</span>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>Хэрэглэгч нь сайт ашиглах үндсэн нөхцөлийг хүлээн зөвшөөрч, албан ёсны мэдээллээ оруулсан хувь хүн, бригад, байгууллага байна.&nbsp;</span>
                </p><br>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <strong><span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>ЦАХИМ СИСТЕМИЙН БҮРТГЭЛИЙН ЗАРЧИМ</span></strong>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>Хэрэглэгч нь&nbsp;</span><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>-ийн үйлчилгээг хэрэглэхээр бүртгүүлэхдээ&nbsp;</span><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>-ийн&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>бүртгэлийн хуудсанд шаардлагатай бүх мэдээллийг үнэн, зөв, бүрэн дүүрэн оруулах ёстой бөгөөд цаг тухай бүрд нь мэдээллийн өөрчлөлтийг үргэлж шинэчлэн &nbsp;хийх, мэдээллийн үнэн зөв, бүрэн, бодит байдал болон хэрэглэгчийн нэр, нууц үг зэргийн хамгаалалтыг өөрөө бүрэн дүүрэн хариуцах үүргийг хүлээнэ.&nbsp;</span><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>-</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>ийн хэрэглэгчид нь үйлчилгээгээ авахдаа&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>&ldquo;</span><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>-</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>ийн хэрэглэгч&rdquo; болж бүртгүүлж байгаа бөгөөд бүртгэл нь сайн дурын үндсэн дээр хийгдэж байгааг ойлгосон байна.</span>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>Нэг&nbsp;</span><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>системд бүртгүүлж буй х</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>эрэглэгч нь зөвхөн нэг л гишүүн байх эрхтэй болно. Ашиглагдсан и-мэйл хаяг нь дахин өөр&nbsp;</span><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>-ийн&nbsp;</span><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>хэрэглэгчид ашиглагдахгүй болно.</span>
                </p><br>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
                    <strong><span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>ХЭРЭГЛЭГЧИЙН БҮРТГЭЛ ТҮҮНИЙ АЮУЛГҮЙ БАЙДАЛ&nbsp;</span></strong>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <span style='font-size:14px;font-family:"Arial",sans-serif;'>Хэрэглэгч нь&nbsp;</span><span
                        style='font-size:14px;font-family:"Arial",sans-serif;'>job.mnca.</span><span
                        style='font-size:14px;font-family:"Arial",sans-serif;'>mn -</span><span
                        style='font-size:14px;font-family:"Arial",sans-serif;'>д бүртгэл үүсгэхдээ өөрийн сонгосон давхардаагүй хэрэглэгчийн нэр болон нууц үгийг үүсгэнэ. Та өөрийн хэрэглэгчийн нэр, нууц үгийг бүрэн хариуцах бөгөөд хэрэглэгчийн бүртгэлээс хийгдсэн бүхий л үйлдлийг хэрэглэгч зөвхөн өөрөө хариуцаж, хариуцлагаа хүлээх болно. Хэрэглэгчийн мэдээлэл нь мэдээллийн аюулгүй баазад бүрэн хадгалагдах тул Та&nbsp;хэрэглэгчийн нэр, нууц үгээ бусдад дамжуулахгүй байж хэрэглэснийхээ дараа бүртгэлээ хааж байхыг анхааралдаа авна уу. Танд мэдэгдэлгүйгээр аливаа этгээд таны бүртгэлийг хэрэглэсэн байж болох сэжиг илэрвэл бидэнд яаралтай мэдэгдэнэ үү.</span>
                </p><br>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>ХЭРЭГЛЭГЧИЙН ХАРИУЦЛАГА</span></strong>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <span style='font-size:14px;font-family:"Arial",sans-serif;'>Хэрэглэгчийн өөрийн бичсэн зурвас, танилцуулга, үйлчилгээ, ажлын байр зэрэг бүхий хувийн болон олон нийтэд зориулсан мэдээ, текст, зураг болон бусад зүйлс нь дан ганц тухайн хэрэглэгчийн хариуцлага байна.&nbsp;</span><u><span
                        style='font-size:14px;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:14px;font-family:"Arial",sans-serif;'>хэрэглэгчийн үйлдэлд ямар нэгэн хариуцлагыг хүлээхгүй бөгөөд тухайн хэрэглэгч нь гуравдагч этгээд болон&nbsp;</span><u><span
                        style='font-size:14px;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:16px;font-family:"Arial",sans-serif;'>системд учруулсан аливаа хохирлыг зохих хууль дүрмийн дагуу барагдуулах үүрэгтэй байна.&nbsp;</span>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <u><span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>-ийн хэрэглэгчдэд дараах зүйлсийг хориглоно. Үүнд:</span>
                </p>
                <ul style="list-style-type: disc;margin-left:8px; padding: 20px;">
                    <li><u><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Зар сурталчилгааны тухай хууль</span></u><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:12.0pt;'>&nbsp;- Зар сурталчилгааг бүтээх, түгээхэд хориглох ерөнхий заалтууд, хууль бус зар сурталчилгаа болон эрүүл мэнд, байгаль орчны салбар, хүүхдийн эрх, банк, санхүү, хөрөнгө оруулалтын сан, даатгалын үйл ажиллагаа, үнэт цаастай холбоотой;</span>
                    </li>
                    <li><u><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Улсын их хурлын сонгуулийн тухай хууль</span></u><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:12.0pt;'>&nbsp;- Улс төрийн чансаа тогтоох зорилгоор шалгаруулалтыг зохион байгуулах, бусдыг гүтгэн доромжлох, хуурамч мэдээ, &nbsp;мэдээлэл тараахтай холбоотой;&nbsp;</span>
                    </li>
                    <li><u><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Гэмт хэргээс урьдчилан сэргийлэх тухай хууль</span></u><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:12.0pt;'>&nbsp;- Хэвлэл, мэдээллийн хэрэгслээр хүчирхийлэл, аллага хядлага, садар самуун, гэмт хэрэг үйлдэхийг өөгшүүлэн сурталчилсан, гэмт хэрэг үйлдэх аргыг нарийвчлан харуулсан нийтлэл, нэвтрүүлэг хийхтэй холбоотой;&nbsp;</span>
                    </li>
                    <li><u><span
                            style='line-height:107%;font-family:"Arial",sans-serif;color:#333333;background:white;font-family:"Arial",sans-serif;font-size:12.0pt;color:#333333;background:white;'>Төрийн болон албаны нууцын тухай хууль</span></u><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:12.0pt;color:#333333;background:white;'>&nbsp;-&nbsp;</span><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:12.0pt;'>Хувийн болон албаны нууц баримтыг олонд дэлгэхтэй холбоотой;&nbsp;</span>
                    </li>
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Зохиогчийн эрхийг зөрчихтэй холбоотой;&nbsp;</span>
                    </li>
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Ямар нэгэн хэлбэрээр зөвшөөрөгдөөгүй зар сурталчилгааны зорилгоор</span><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-size:16.0pt;'>&nbsp;</span><span
                            style="font-size:12.0pt;">&quot;спам&quot; эсвэл хүсээгүй арилжааны цахим мессежийг илгээхтэй холбоотой;&nbsp;</span>
                    </li>
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Бусдад хохирол үзүүлэх зорилгоор вирус болон бусад кодыг илгээхтэй холбоотой;&nbsp;</span>
                    </li>
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Санаатай болон санамсаргүйгээр бүхий төрлийн хууль, дүрмийг зөрчих зэрэгт ашиглахтай холбоотой зэрэг болно.</span>
                    </li>
                </ul>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>Эдгээр болон хуульд харшлах бусад нөхцөлд&nbsp;</span><u><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>нь тухайн гишүүний эрхийг хасах үйлчилгээг үзүүлэхийг зогсоох эрхтэй бөгөөд тухайн хэрэглэгчтэй холбоотой мэдээллийг хуулийн байгууллагын зүгээс шаардсан тохиолдолд холбогдох байгууллагад шилжүүлэх эрхтэй.</span>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <strong><span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></strong>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>Энэхүү Үйлчилгээний нөхцөлийг зөрчсөн тохиолдолд Аж ахуйн нэгж байгууллага (ААНБ) болон бригадад дараах 3 үндсэн арга хэмжээг авна. Үүнд:</span>
                </p>
                <ul style="list-style-type: disc;margin-left:8px; padding: 20px;">
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Үйлчилгээг хаах,</span>
                    </li>
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Хэрэглэгчийн бүртгэлээс хасах.&nbsp;</span>
                    </li>
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Дараах зөрчил илэрсэн тохиолдолд хэрэглэгчийн бүртгэлээс хасна. Үүнд:</span>
                    </li>
                </ul>
                <ul style="list-style-type: undefined;  padding: 20px;">
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Хуулийн байгууллагад хандах,</span>
                    </li>
                    <li><span
                            style='line-height:107%;font-family:"Arial",sans-serif;font-family:"Arial",sans-serif;font-size:11.0pt;'>Үйлчилгээнд өөрчлөлт оруулах зэрэг байна.</span>
                    </li>
                </ul>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <u><span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><strong><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>-ИЙН ӨМЧЛӨГЧИЙН ЭРХ</span></strong>
                </p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'>
                    <u><span style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>сайтад нийтлэгдэж байгаа ажлын байрны болон туслан гүйцэтгүүлэх ажлын зар нь</span><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>job.mnca.</span></u><u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>mn</span></u><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><span
                        style='font-size:14px;line-height:107%;font-family:"Arial",sans-serif;'>-ийн өмч байна. Нийтлэгдсэн ажлын байрны зарыг зөвшөөрөлгүйгээр бусдад дамжуулах, хуулбарлан түгээхийг Монгол Улсын Иргэний тухай хууль, Төрийн болон орон нутгийн өмчлөгчийн тухай хуулиар хориглоно.</span>
                </p>
            </div>
        </Modal>

        <Drawer width="640" v-model="savedItemsShow">
            <SavedItems v-if="savedItemsShow"/>
        </Drawer>
        <Drawer width="640" v-model="savedItemsTwo">
            <SavedItemsTwo v-if="savedItemsTwo"/>
        </Drawer>

<!--        <Drawer  width="640" v-model="flowedShow">-->

        <Drawer width="640" v-model="flowedShow">
          <Flowed v-if="flowedShow"/>
        </Drawer>
    </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {GET_ANKET} from "../graphql/anket"

const Dataform = () => import(/* webpackChunkName: "Dataform-el" */'@lambda-platform/dataform/src/Dataform.vue')
const SavedItems = () => import(/* webpackChunkName: "savedItems" */ './savedItems')
const SavedItemsTwo = () => import(/* webpackChunkName: "savedItems" */ './savedItems2')
const Flowed = () => import(/* webpackChunkName: "flowed" */ './flowed')
export default {
    name: "app-header",
    components: {
        "dataform": Dataform,
        "SavedItems": SavedItems,
        "SavedItemsTwo": SavedItemsTwo,
        "Flowed": Flowed
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'languages',
            'language',
            'user',
            'userToken',
            'anket_id'
            // ...
        ]),

        ruleValidate(){
            return{
                name: [
                    {required: true, message: this.$t('name_cannot_empty'), trigger: 'blur'}
                ]
            }
        },
        loginRule(){
            return{
                login: [
                    {required: true, message: this.$t('Enter_your_email_address'), trigger: 'blur'}
                ],
                password: [
                    {required: true, message: this.$t('Enter_your_password'), trigger: 'blur'},
                    // { type: 'string', min: 6, message: 'The password length cannot be less than 6 bits', trigger: 'blur' }
                ]
            }
        },
    },
    mounted() {
      if(this.user){
        if (this.user.id) {
          this.getSavedAnket();
        }
      }

    },
    methods: {
        openUrl(url) {
            window.open(url, '_blank').focus();
        },
        logout() {
            localStorage.removeItem('user_token');
            localStorage.removeItem('user');
            this.$store.commit('setStorageValues');
            this.$router.push("/")
            window.location.reload();
        },
        changeLocale(locale) {
            Vue.i18n.set(locale.short);
            this.$store.commit('setLanguage', locale);
        },
        login(formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    this.isSuccess = false;
                    this.isError = false;

                    this.$http.post('/auth/login', this.loginForm, {withCredentials: true}).then(({data}) => {
                        if (data.status) {
                            this.loading = false;
                            this.isError = false;
                            this.isSuccess = true;
                            this.msg = data.msg;
                            this.$store.commit('setUser', data.data);
                            this.$store.commit('setUserToken', data.token);
                            this.loginRegisterShow = false;
                            // setTimeout(() => {
                            //     this.$router.push("/")
                            //     // window.location.reload();
                            // }, 1000);
                            this.getSavedAnket();
                        } else {
                            this.$store.commit('setUser', null);
                            this.$store.commit('setUserToken', null);
                            this.loading = false;
                            this.isError = true;
                            this.msg = this.$t("nevtreh_medeelel_buruu");
                            // }, 1000);
                        }
                        // eslint-disable-next-line no-unused-vars
                    }).catch(e => {
                        setTimeout(() => {
                            this.$store.commit('setUser', null);
                            this.$store.commit('setUserToken', null);
                            this.loading = false;
                            this.isError = true;
                            this.isSuccess = false;
                            this.msg = this.$t("nevtreh_medeelel_buruu");
                        }, 1000);
                    })
                }
            })
        },
        onError() {
            this.$Notice.error({
                title: this.$t("error"),
                desc: this.$t("error_occurred_submitting")
            });
        },
        onSuccess() {
            this.$Notice.success({
                title: this.$t("successful"),
                desc: this.$t("Your_account_created_successfully")
            });
        },
        onSuccessAnket(data) {
          this.$Notice.success({
                title: 'Амжилттай',
                desc: 'Таны анкет амжилттай илгээгдлээ'
          });
          this.$store.commit("setAnketID", data.id);
          this.anketModal = false;
        },
        readyAnketForm() {
            if (this.anket_id >= 1) {
                this.$refs.anketForm.editModel(this.anket_id);
            }
        },
        send() {
            this.$refs.anketForm.handleSubmit("users-404");
        },
        getSavedAnket() {
            this.$apollo.query({
                query: GET_ANKET,
                variables: {
                  user_id: this.user.id,
                },
            }).then(({data}) => {
                if (data.engineer_teknical_ajiltan.length >= 1) {
                    this.$store.commit("setAnketID", data.engineer_teknical_ajiltan[0].id);
                }
            })
        }
    },

    data() {
        return {
            tab: 'login',
            checkTerm: false,
            anketModal: false,
            termModal: false,
            loginRegisterShow: false,
            active: false,
            isSuccess: false,
            isError: false,
            theme1: 'light',
            formValidate: {
                name: ''
            },
            loginForm: {
                login: '',
                password: ''
            },
            registerForm: {
                email: '',
                family_name: '',
                phone: '',
                first_name: '',
                last_name: '',
                birthday: '',
                gender: '',
                register_number: '',
                password: '',
                passwordCheck: ''
            },
            registerRule: {
                email: [
                    {
                        required: true,
                        message: "Та и-мэйл хаягаа бичнэ үү",
                        trigger: "blur"
                    },
                    {
                        type: "email",
                        message: "Та зөв и-мэйл хаяг бичнэ үү",
                        trigger: "blur"
                    }
                ],
                phone: [
                    {
                        required: true,
                        message: "Та утасны дугаараа бичнэ үү",
                        trigger: "blur"
                    },
                    {
                        min: 8,
                        max: 8,
                        message: "Гар утасны дугаар 8 оронтой байх ёстой!",
                        trigger: "blur"
                    }
                ],
                password: [
                    {required: true, message: 'Нууц үгээ оруулна уу', trigger: 'blur'},
                ],
                passwordCheck: [
                    {required: true, message: 'Нууц үгээ оруулна уу', trigger: 'blur'},
                ],
                first_name: [
                    {
                        required: true,
                        message: "Та нэрээ бичнэ үү",
                        trigger: "blur"
                    },
                    {
                        min: 2,
                        message: "Таны нэр хамгийн багадаа 2 үсгээс багагүй байх ёстой!",
                        trigger: "blur"
                    },
                    {
                        max: 75,
                        message: "Таны нэр хамгийн ихдээ 75 үсгээс ихгүй байх ёстой!",
                        trigger: "blur"
                    }
                ],
                last_name: [
                    {
                        required: true,
                        message: "Та овгоо бичнэ үү",
                        trigger: "blur"
                    },
                    {
                        min: 2,
                        message: "Таны овог хамгийн багадаа 2 үсгээс багагүй байх ёстой!",
                        trigger: "blur"
                    },
                    {
                        max: 75,
                        message: "Таны овог хамгийн ихдээ 75 үсгээс ихгүй байх ёстой!",
                        trigger: "blur"
                    }
                ],
                birthday: [
                    {
                        required: true,
                        message: "Төрсөн огноогоо сонгоно уу",
                        trigger: "change"
                    }
                ],
                gender: [
                    {
                        required: true,
                        message: "Хүйсээ сонгоно уу ",
                        trigger: "change"
                    }
                ],
            },
            savedItemsShow: false,
            savedItemsTwo: false,
            flowedShow: false,
            pStyle: {
                fontSize: '16px',
                // color: 'rgba(0,0,0,0.85)',
                lineHeight: '24px',
                display: 'block',
            },
            loading: true,
            selectCompany: null,
        }
    },
}
</script>
<style>

</style>
