export default {
    "home": "HOME",
    "uilch_nuhtsul": "Terms of service",
    "help_v": "Help",
    "holboo_barih": "Contact Us ",
    "loginreg": "Login & Register",
    "_sending": "Sending",
    "news": "News",
    "dur_juram": "Rules",
    "advice_information":"Advice & Information",
    "sanaL_hus": "Feedback",
    "hadgalsan":"Saved",
    "medegdel":"Notification",
    "ajliin_bairnii_zar": "POSTED JOBS ",
    "zahialgat_ajil": "POSTED WORKS",
    "Ajil_olgogch": "EMPLOYER",
    "hailt":"Search...",
    "log-in":"LOGIN",
    "register":"REQISTER",
    "log-in1":"Login",
    "register1":"Register",
    "email":"Email",
    "pass":"Password",
    "forgot_pass":"Forgot password",
    "last_name":"Last Name",
    "first_name":"First Name",
    "date_of_birth":"Date of Birth",
    "gender":"Gender",
    "male":"Male",
    "female":"Female",
    "phone":"Phone number",
    "confirm_pass":"Confirm Password",
    "uilchilgeenii":"TERMS OF",
    "nuhtsul":"SERVICE",
    "uilchilgeenii_nukhtsul":"TERMS OF SERVICE",
    "gishuun_bagts":"MEMBERSHIP PACKAGE OF MNCA NGO",
    "gishuun_bus_bagts":"CONSUMER PACKAGE",
    "watch":"Watch",
    "gold":"GOLDEN PACKAGE",
    "gold_presidents":"Presidents of the MNCA NGO",
    "silver":"COLD PACKAGE",
    "silver_members":"МБҮА ТББ-ын УЗГишүүд vgjf",
    "bronze":"BRONZE PACKAGE",
    "bronze_members":"МБҮА ТББ-ын Гишүүд dkfmkd",
    "observer_member_set":"Observer member set",
    "service_date":"Service date",
    "job_posting":"posted works",
    "application_base":"Application",
    "other":"Other",
    "package_service":"package service is free",
    "package_service1":"Package service payment",
    "common":"COMMON",
    "Q_and_A":"Questions & Answers",
    "select":"Select",
    "sistemiin_hundalt":"system access",
    "send":"Send",
    "name":"Name",
    "tsahim_hayag":"Email address",
    "today":"Today",
    "week":"Week",
    "month":"Month",
    "year":"Year",
    "total":"Total",
    "huil_zuin_erkh":"All rights reserved © 2022",
    "company_name":"Mongolian National Construction Association NGO",
    "news1":"News &",
    "information":"Information",
    "advice":"Advice &",
    "durem":"RULAS &",
    "juram":"REQULATIONS",
    "this_month":"Month",
    "this_year":"Year",
    "baiguullaga": "Organization",
    "brigad": "Brigade",
    "total_ad": "Total ad",
    "total_employer": "Total employer",
    "zarlagdsan":"Posted",
    "ajliin_zar":"Posted jobs",
    "bugdiig_harah":"See all",
    "where":"Where",
    "zarlasan_ognoo":"Announced date",
    "anket_avah_ognoo":"Application date",
    "detail":"Detail",
    "zahialgat":"Posted",
    "ajil":"Employer",
    "guitsetgegch":"Performer",
    "hamtragch":"Partner",
    "baiguullaguud":"Organizations",
    "mergejliin_chigleleer":"Professionally directions",
    "mergejleer":"Professionally",
    "mergejil_songoh":"Choosing a profession",
    "mergejil":"Profession",
    "tsagiin_turul":"Time type",
    "tsalin_huls":"Salary",
    "chiglel_songoh":" Choose the direction",
    "baiguullagaar_haih":"Search by organization",
    "brigadaar_haih":"Search by bridge",
    "tsagiin_turul_songoh":"Choose a time type",
    "tsalingiin_unelgee_songoh":"Choose a salary rating",
    "dulaan_shiidel":"Thermal solution project",
    "ontslokh":"Featured",
    "ajliin_turul":"Choose the type of work",
    "job":"TYPE OF WORK",
    "ajliin_turul_songoh":"Choose the type of work",
    "guitsetgeh_undsen_uureg":"MAIN ROLE",
    "tavigdah_shaardlaga":"REQUIREMENTS",
    "nemelt_medeelel":"ADDITIONAL INFORMATION",
    "busad_medeelel":"OTHER INFORMATION",
    "tsalin_hulsnii_turul":"Salary type",
    "aimag_niislel":"Aimag / Capital city",
    "sum_duureg":"Sum / District",
    "bag_horoo":"Bag / Committee",
    "anket_ilgeeh":"SEND RESUME",
    "sanal_ilgeeh":"SEND A VOTE",
    "ijil_ajliin_zar":"THE SAME ADVERTISEMENTS FOR THIS JOB",
    "zarlagdsan_ijil_zaruud":"OTHER ADS",
    "hun":"The profession and staff required to perform the job",
    "tuz":"BOD",
    "salbar":"Industry",
    "tsagUyiin":"Contemporary",
    "dagah":"Followed",
    "garah":"Exit",
    "anket_bugluh":"Fill a resume",
    "brigad_udirdlaga":"Brigade management",
    "baiguullagiin_udirdlaga":"Organization management",
    "amjilttai_nevtrelt":"Successfully logged in. Please wait for a moment!",
    "netrehed_aldaa":"An error occurred while logging in. Please check your login information and try again!",
    "nevtreh_medeelel_buruu":"The login information is incorrect.",
    "hadgalsan_ajliin_bairnii_zar": "Saved posted works ",
    "hadgalsan_zahialgat_ajil": "Saved posted jobs",
    "ankit_ilgeesen":"Application form sent",
    "baiguullagin_ajliin_bairnii_zar":"Work postings for the organization",
    "brigad_ajliin_bairnii_zar":"Brigade work postings",
    "baiguullagin_ajliin_zar":"Organization job postings",
    "brigad_ajliin_zar":"Brigade job postings",
    "ta_ene_bagts_avah_uu":"Will you get this package?",
    "ugui":"No",
    "tiim":"Yes",
    "bagts_avah":"Get a package",
    "avsan_bagts":"Received package",
    "shiljuulgiin_medeelel":"TRANSACION INFORMATION",
    "shiljuuleh_dans":"Transfer account",
    "huleen_avagch":"Recipient",
    "guilgeenii_utga":"Transaction value",
    "tuluv":"State",
    "huchintei_hugatsaa":"Valid date",
    "huleegdej_bui":"Expected",
    "batalgaajsan":"Confirmed",
    "butsaagdsan":"Returned",
    "mnca":"MONGOLIA NATIONAL CONSTRUCTION ASSOCIATION NGO",
    "hudaldaa_hugjliin_bank":"Trade Development Bank 499136297",
    "idevhtei_bagts":"Active package",
    "aldaa":"Error",
    "aimagcapitalcity":"Aimag & Capitalcity",
    "sumdistrict":"Sum & District",
    "olgogch":"Employer",
    "ankit":"Application form",
    "successful":"Successful",
    "no_saved_organization":"There are no saved organization.",
    "no_saved_brigade":"There are no saved brigade.",
    "online":"There is an online person on the website right now.",
    "your_ip":"Your IP:",
    "i_agree":"I agree.",
    "Get_acquainted_terms_of_service":"Get acquainted with the terms of service",
    "Date_of_publication":"Date of publication",
    "Expiry_date":"Expiry date",
    "no_saved_ads.":"There are no saved ads.",
    "Expiry":"Expiry",
    "date":"date",
    "publication":"Date of",
    "p_date":"publication",
    "clear":"clear",
    "employerStatus":"Employer status",
    "direction_activity":"Direction of activity",
    "Choose_status":"Choose a status",
    "Choose_field_activity":"Choose a field of activity",
    "search":"SEARCH",
    "introduction":"Introduction",
    "partner_organizations":"PARTNER ORGANIZATIONS",
    "payment":"Payment",
    "work_carried_out_according_plan":"Whether the work was carried out according to the plan",
    "Making_optimal_decisions_progress_work":"Making optimal decisions on the progress of the work",
    // "performed_works":"PERFORMED WORKS",
    "projects":"PROJECTS",
    "inProgress":"In progress.",
    "customer":"Customer",
    "start_date":"Start date",
    "completion_date":"Completion date",
    "There_no_work_done":"There is no work done.",
    "no_job_postings":"There are no job postings.",
    "no_ads":"No ads",
    "Date_commencement_operations":"Date of commencement of operations",
    "ajliin_bairnii_zaruud": "POSTED JOBS",
    "zahialgat_ajil_zaruud": "POSTED WORKS",
    "hayag":"Address",
    "Details_work_performed":"Details of work performed",
    "There_picture_work_done":"There is no picture of the work done",
    "Date_commencement_work":"Date of commencement of work",
    "Date_completion_work":"Date of completion of work",
    "evaluation_customer_performance":"EVALUATION FROM THE CUSTOMER TO THE PERFORMANCE",
    "elaluation_of_customers":"EVALUATION OF CUSTOMERS",
    "contract_performance":"Contract performance",
    "Capacity_competence_employees":"Capacity and competence of employees",
    "Responsibility_work_performed":"Responsibility for work performed",
    "correcthess":"Correcthess",
    "send_feedback":"Send feedback",
    "ORGANIZATION_NOT_FOUND":"ORGANIZATION NOT FOUND",
    "BRIGADE_NOT_FOUND":"BRIGADE NOT FOUND",
    "Send_a_quote":"Send a quote",
    "LOOKING_FOR_JOB":"LOOKING FOR A JOB",
    "VIDEO":"VIDEO",
    "COMMON_QUESTIONS_AND_ANSWERS":"COMMON QUESTIONS AND ANSWERS",
    "successfully_Deleted":"Successfully deleted",
    "Your_application_successfully_submitted":"Your application has been successfully submitted.",
    "failed":"Failed",
    "Feedback_sent_successfully":"Feedback sent successfully.",
    "Please_fill_field":"Please fill in the field !!!",
    "error":"Error",
    "error_occurred_submitting":"An error occurred while submitting.",
    "Your_account_created_successfully":"Your account has been created successfully.",
    "Successfully_saved":"Successfully saved.",
    "Followed_organization_menu":"The followed organization went to the menu.",
    "Followed_brigade_menu":"The followed brigade went to the menu.",
    "Your_saved_ads_Saved_Jobs_ads_menu":"Your saved ads is in the Saved Jobs ads menu.",
    "Your_saved_ads_Saved_Works_ads_menu":"Your saved ads is in the Saved Works ads menu.",
    "Notices":"NOTICES",
    "Please_enter_your_name":"Please enter your name!",
    "Enter_your_email_address":"Enter your email address!",
    "Enter_the_correct_email_address":"Enter the correct email address!",
    "Write_your_comments":"Write your comments!",
    "Write_at_least_20_characters":"Write at least 20 characters!",
    "Write_maximum_500_characters":"Write a maximum of 500 characters!",
    "name_cannot_empty":"The name cannot be empty!",
    "Enter_your_password":"Enter your password!",
};
