<template>
    <div>
        <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="home">
            <div class="b_top_items">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                          <router-link :to="`/ajliin_bairnii_zar`">
                            <div class="a_zar color1">
                            <div class="title">
                              <img src="../assets/injineer_icon.svg" alt="">
                              <h3>{{ 'ajliin_bairnii_zar' | translate }}</h3>
                            </div>
                            <div class="total">
                              <div class="mount_year">
                                <p>{{ 'this_month' | translate }}</p>
                                <div class="num">
                                  +{{ ds_ajil_bair_zar_view.length }}
                                </div>
                              </div>
                              <div class="mount_year">
                                <p>{{ 'this_year' | translate }}</p>
                                <div class="num">
                                  +{{ ds_brigad_ajilin_bair_zar_view.length }}
                                </div>
                              </div>
                              <div class="all color2">
                                <p>{{ 'total_ad' | translate }}</p>
                                <div class="total_num">{{ ds_ajil_bair_zar_view.length + ds_brigad_ajilin_bair_zar_view.length}}</div>
                              </div>
                            </div>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-md-4">
                          <router-link :to="`/ajliin_zar`">
                            <div class="a_zar color1">
                            <div class="title">
                              <img src="../assets/paint_icon.svg" alt="">
                              <h3>{{ 'zahialgat_ajil' | translate }}</h3>
                            </div>
                            <div class="total">
                              <div class="mount_year">
                                <p>{{ 'this_month' | translate }}</p>
                                <div class="num">
                                  +{{ baiguullaga_ds_ajiliin_zar_view.length }}
                                </div>
                              </div>
                              <div class="mount_year">
                                <p>{{ 'this_year' | translate }}</p>
                                <div class="num">
                                  +{{ ds_brigad_ajil_zar_view.length }}
                                </div>
                              </div>
                              <div class="all color3">
                                <p>{{ 'total_ad' | translate }}</p>
                                <div class="total_num">{{ baiguullaga_ds_ajiliin_zar_view.length + ds_brigad_ajil_zar_view.length}}</div>
                              </div>
                            </div>
                          </div>
                          </router-link>
                        </div>
                      <div class="col-md-4">
                        <router-link :to="`/ajilolgogch`">
                          <div class="a_zar color1">
                            <div class="title">
                              <img src="../assets/ajil_olgogch_icon.svg" alt="">
                              <h3>{{ 'Ajil_olgogch' | translate }}</h3>
                            </div>
                            <div class="total">
                              <div class="mount_year">
                                <p>{{ 'baiguullaga' | translate }}</p>
                                <div class="num_black">
                                  {{ ds_baiguullaga.length }}
                                </div>
                              </div>
                              <div class="mount_year">
                                <p>{{ 'brigad' | translate }}</p>
                                <div class="num_black">
                                  {{ ds_brigad_view.length }}
                                </div>
                              </div>
                              <div class="all color4">
                                <p>{{ 'total_employer' | translate }}</p>
                                <div class="total_num"> {{ ds_baiguullaga.length + ds_brigad_view.length }}</div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                </div>
            </div>
            <!--SLIDE video nad banner-->
            <div class="TOP_SLIDE">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 video_slide">
                            <div class="video_slide_item" v-if="videoList.video">
                                <youtube :video-id="getVideo(videoList.video)"  player-width="100%" player-height="400"></youtube>
                            </div>
                        </div>
                        <div class="col-md-5 banner">
                            <div>
                                <div class="banner_one" v-if="banner1 != null">
                                    <img :src="IMAGE_URL + banner1.unelgee">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--ЗАРЛАГДСАН АЖЛЫН БАЙР-->
            <div class="zarlagdsan_ajliin_bair">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="left_theme">
                                <div class="zarlagdsan_ajliin_bair_title"><span
                                        class="yelow">{{ 'ontslokh' | translate }}</span> {{ 'ajliin_zar' | translate
                                    }}
                                    <Button type="primary" class="read_more">
                                        <img src="../assets/all_view.png" alt="">
                                        {{ 'bugdiig_harah' | translate }}
                                    </Button>
                                </div>
                                <div class="p_table">
                                    <table width="100%">
                                        <tbody>
                                        <!--tr-->
                                        <div class="title">{{ 'baiguullaga' | translate }}</div>
                                        <home_baiguullaga_ajil_bair_zar></home_baiguullaga_ajil_bair_zar>
                                        <div class="title">{{ 'brigad' | translate }}</div>
                                        <home_barigad_ajil_bair_zar></home_barigad_ajil_bair_zar>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="right_banner">
                                <div class="b_photo" v-if="banner2 != null">
                                    <img alt="" :src="IMAGE_URL + banner2.unelgee">
                                </div>
                                <div class="b_photo" v-if="banner3 != null">
                                  <img alt="" :src="IMAGE_URL + banner3.unelgee">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--МЭДЭЭ, МЭДЭЭЛЭЛ-->
            <div class="medee_medeelel">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="medee_medeelel_title"><span class="yelow">{{ 'news1' | translate
                                }}</span>{{ 'information' | translate }}
                                <router-link to="/p_news" class="read_more"><img src="../assets/all_view.png" alt="">
                                    {{ 'bugdiig_harah' | translate }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="medee_medeelel_slide">
                                <div class="sub-menu">
                                    <ul>
                                        <li :key="index" v-for="(news_category, index) in news_categorys">
<!--                                          :class="[(activeItem == news_category.id) ? 'visible':'']"-->
                                            <a href="javascript:void(0);" class="item"
                                               :class="[index+1===activeItem ? 'visible' : '']"
                                               @click="callNewsData(news_category.id)">{{ news_category.medee_angilal }}</a>
                                        </li>
                                    </ul>
                                </div>
                                <swiper class="swiper" :options="medee_medeelel_slide">
                                    <swiper-slide v-show="index <= 4" :key="index" v-for="(news, index) in news_datas">
                                        <div class="slide">
                                            <router-link :to="'/news_sub/' + news.id">
                                                <div class="view view-first">
                                                    <img :src="IMAGE_URL + news.zurag" alt="">
                                                </div>
                                                <div class="repost-text">
                                                    <Button type="primary" class="read">{{ news.medee_angilal }}
                                                    </Button>
                                                    <router-link :to="'/news_sub/' + news.id">
                                                        <h4 v-if="language.short == 'mn'"> {{ news.garchig_mn }} </h4>
                                                        <h4 v-else> {{ news.garchig_en }} </h4>
                                                    </router-link>
                                                </div>
                                                <ul class="post-tags clearfix">
                                                    <li>{{ news.created_at | date }}</li>
                                                    <li class="rigth">
                                                        <router-link :to="'/news_sub/' + news.id">
                                                            {{ 'detail' | translate }}<i
                                                                class="ivu-icon ivu-icon-md-return-right"></i>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </router-link>
                                        </div>
                                    </swiper-slide>
                                    <div class="swiper-pagination" slot="pagination"></div>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--ЗАХИАЛГАТ АЖИЛ-->
            <div class="zarlagdsan_ajliin_bair">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="left_theme">
                                <div class="zarlagdsan_ajliin_bair_title"><span class="yelow">{{ 'ontslokh' | translate
                                    }}</span> {{ 'zahialgat_ajil' | translate }}
                                    <Button type="primary" class="read_more"><img src="../assets/all_view.png" alt="">
                                        {{ 'bugdiig_harah' | translate }}
                                    </Button>
                                </div>
                                <div class="p_table">
                                    <table width="100%">
                                        <tbody>
                                        <div class="title">{{ 'baiguullaga' | translate }}</div>
                                        <home_baiguullaga_ajil_zar></home_baiguullaga_ajil_zar>
                                        <div class="title">{{ 'brigad' | translate }}</div>
                                        <home_brigad_ajil_zar></home_brigad_ajil_zar>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="right_banner">
                                <div class="b_photo" v-if="banner4 != null">
                                    <img alt="" :src="IMAGE_URL + banner4.unelgee">
                                </div>
                                <div class="b_photo" v-if="banner5 != null">
                                    <img alt="" :src="IMAGE_URL + banner5.unelgee">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--        <div class="container">-->
            <!--            <div class="row">-->
            <!--                <div class="col-md-12">-->
            <!--                    <div class="mergejil_statistic_title"><span>ЭРЭЛТТЭЙ</span> МЭРГЭЛЖИЛ</div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </div>-->

            <!--        <div class="mergejil_statistic_2">-->
            <!--            <div class="container">-->
            <!--                <div class="row">-->
            <!--                    <div class="col-md-6 col-lg-3 counter-section__box">-->
            <!--                        <span class="counter" data-count="80">80</span>-->
            <!--                        <p>-->
            <!--                            ГАГНУУРЧИН-->
            <!--                        </p>-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-lg-3 counter-section__box">-->
            <!--                        <span class="counter" data-count="75">75</span>-->
            <!--                        <p>-->
            <!--                            ӨРЛӨГЧИН-->
            <!--                        </p>-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-lg-3 counter-section__box">-->
            <!--                        <span class="counter" data-count="40">40</span>-->
            <!--                        <p>-->
            <!--                            АРМАТУРЧИН-->
            <!--                        </p>-->
            <!--                        &lt;!&ndash;               <p>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                 OUR&ndash;&gt;-->
            <!--                        &lt;!&ndash;                 <br> TEAM&ndash;&gt;-->
            <!--                        &lt;!&ndash;               </p>&ndash;&gt;-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-lg-3 counter-section__box">-->
            <!--                        <span class="counter" data-count="70">70</span>-->
            <!--                        <p>-->
            <!--                            ДОТОР ЗАСАЛТЧ-->
            <!--                        </p>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </div>-->

            <!--ЗӨВӨЛГӨӨ, МЭДЭЭЛЭЛ-->
            <z_news></z_news>

            <!--АЖИЛ ОЛГОГЧ / ГҮЙЦЭТГЭГЧ-->
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mergejil_statistic_title"><span class="span_text">
                            {{ 'ajil' | translate }} </span> {{ 'olgogch' | translate }}
                            <Button type="primary" class="read_more"><img src="../assets/all_view.png" alt="">
                                {{ 'bugdiig_harah' | translate }}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ajil_olgogch_gvitsedgegch">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <Tabs value="name1">
                                <TabPane :label="'baiguullaga' | translate" name="name1">
                                    <baiguullaga></baiguullaga>
                                </TabPane>
                                <TabPane :label="'brigad' | translate" name="name2">
                                    <brigad></brigad>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

            <!--ХАМТРАГЧ БАЙГУУЛЛАГУУД-->
            <div class="hamtragch_baiguullaga">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="hamtragch_baiguullaga_title">
                                <span>{{ 'hamtragch' | translate }}</span>{{ 'baiguullaguud' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AAN></AAN>
        </div>
    </div>
</template>

<script>
const AAN = () => import(/* webpackChunkName: "hamtragch_baiguullaga" */ './section/hamtragch_baiguullaga')
const z_news = () => import(/* webpackChunkName: "zuvulguu_medeelel" */ './section/zuvulguu_medeelel')
const baiguullaga = () => import(/* webpackChunkName: "baiguullaga" */ './section/baiguullaga')
const brigad = () => import(/* webpackChunkName: "brigad" */ './section/brigad')
const home_baiguullaga_ajil_bair_zar = () => import(/* webpackChunkName: "home_baiguullaga_ajil_bair_zar" */ './section/home_zaruud/home_baiguullaga_ajil_bair_zar')
const home_barigad_ajil_bair_zar = () => import(/* webpackChunkName: "home_barigad_ajil_bair_zar" */ './section/home_zaruud/home_barigad_ajil_bair_zar')
const home_baiguullaga_ajil_zar = () => import(/* webpackChunkName: "home_baiguullaga_ajil_zar" */ './section/home_zaruud/home_baiguullaga_ajil_zar')
const home_brigad_ajil_zar = () => import(/* webpackChunkName: "home_brigad_ajil_zar" */ './section/home_zaruud/home_brigad_ajil_zar')
import {getIdFromURL} from 'vue-youtube-embed';
import {
  GET_NEWS_BY_CATEGORY_ID,
  GET_MEDEE_ANGILAL,
  IMAGE_URL,
  GET_BANNER_VIEW,
  GET_CONTACT_US,
  GET_BAIGUULLAGA,
  GET_BRIGAD_VIEW,
  GET_AJIL_BAIR_ZAR_VIEW,
  GET_BRGD_AJLIIN_BAIR_ZAR_VIEW,
  GET_AJILIIN_ZAR_VIEW,
  GET_BRGD_AJILIIN_ZAR_VIEW
} from "../graphql/queries";
import {mapGetters} from "vuex";

export default {
    name: "home",
    components: {
        "AAN": AAN,
        "z_news": z_news,
        "brigad": brigad,
        "baiguullaga": baiguullaga,
        "home_baiguullaga_ajil_bair_zar": home_baiguullaga_ajil_bair_zar,
        "home_barigad_ajil_bair_zar": home_barigad_ajil_bair_zar,
        "home_baiguullaga_ajil_zar": home_baiguullaga_ajil_zar,
        "home_brigad_ajil_zar": home_brigad_ajil_zar,
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ]),

    },
    mounted() {
        this.getGet_news_category();
        this.callNewsData();
        // this.getAjiliin_bairnii_zar();
        // this.getAjiliin_zar();
        this.getBanner();
        this.getContacVideo();
        this.getGet_baiguullaga();
        this.getBrigad();
        this.get_AJIL_BAIR_ZAR_VIEW();
        this.get_BRGD_AJLIIN_BAIR_ZAR_VIEW();
        this.get_GET_AJILIIN_ZAR_VIEW();
        this.get_BRGD_AJILIIN_ZAR_VIEW();
    },
    methods: {
      getGet_baiguullaga() {
        this.$apollo.query({query: GET_BAIGUULLAGA}).then(({data}) => {
          this.ds_baiguullaga = data.ds_baiguullaga;
        })
      },
      getBrigad() {
        this.$apollo.query({query: GET_BRIGAD_VIEW}).then(({data}) => {
          this.ds_brigad_view = data.ds_brilgas_view;
        })
      },
      get_AJIL_BAIR_ZAR_VIEW() {
        this.$apollo.query({query: GET_AJIL_BAIR_ZAR_VIEW}).then(({data}) => {
          this.ds_ajil_bair_zar_view = data.ds_ajil_bair_zar_view;
          // console.log("this.ds_ajil_bair_zar_view");
          // console.log(this.ds_ajil_bair_zar_view);
        })
      },
      get_BRGD_AJLIIN_BAIR_ZAR_VIEW() {
        this.$apollo.query({query: GET_BRGD_AJLIIN_BAIR_ZAR_VIEW}).then(({data}) => {
          this.ds_brigad_ajilin_bair_zar_view = data.ds_brigad_ajilin_bair_zar_view;
          // console.log("this.ds_brigad_ajilin_bair_zar_view");
          // console.log(this.ds_brigad_ajilin_bair_zar_view);
        })
      },
      get_GET_AJILIIN_ZAR_VIEW() {
        this.$apollo.query({query: GET_AJILIIN_ZAR_VIEW}).then(({data}) => {
          this.baiguullaga_ds_ajiliin_zar_view = data.ds_ajiliin_zar_view;
          // console.log("this.baiguullaga_ds_ajiliin_zar_view");
          // console.log(this.baiguullaga_ds_ajiliin_zar_view);
        })
      },
      get_BRGD_AJILIIN_ZAR_VIEW() {
        this.$apollo.query({query: GET_BRGD_AJILIIN_ZAR_VIEW}).then(({data}) => {
          this.ds_brigad_ajil_zar_view = data.ds_brigad_ajil_zar_view;
          // console.log("this.ds_brigad_ajil_zar_view");
          // console.log(this.ds_brigad_ajil_zar_view);
        })
      },
        // getAjiliin_bairnii_zar() {
        //     this.loading = true;
        //     this.$apollo.query({query: GET_AJIL_BAIR_ZAR_VIEW_LIMIT5}).then(({data}) => {
        //       this.ajiliin_bairnii_zar = data.ds_ajil_bair_zar_view;
        //       this.$apollo.query({
        //             query: GET_BRGD_AJLIIN_BAIR_ZAR_VIEW_LIMIT5,
        //         }).then(({data}) => {
        //             this.ajiliin_bairnii_zar = [...this.ajiliin_bairnii_zar, ...data.ds_brigad_ajilin_bair_zar_view.map(row => {
        //                 return {
        //                     id: row.id,
        //                     aimagname: row.aimagname,
        //                     ajiliin_torol: row.ajiliin_torol,
        //                     ner_mnbaiguullaga: row.brigad_ner,
        //                      logo: row.logo,
        //                     d_ognoo: row.d_ognoo,
        //                     e_ognoo: row.e_ognoo,
        //                     tsagiin_torol: row.tsagiin_torol,
        //                 }
        //             })];
        //             // console.log("this.ajiliin_bairnii_zar");
        //             // console.log(this.ajiliin_bairnii_zar);
        //         })
        //         this.loading = false;
        //     })
        // },
        // getAjiliin_zar() {
        //     this.loading = true;
        //     this.$apollo.query({query: GET_AJILIIN_ZAR_VIEW_LIMIT5}).then(({data}) => {
        //         this.ajiliin_zar = data.ds_ajiliin_zar_view;
        //         this.$apollo.query({
        //             query: GET_BRGD_AJILIIN_ZAR_VIEW_LIMIT5,
        //         }).then(({data}) => {
        //             this.ajiliin_zar = [...this.ajiliin_zar, ...data.ds_brigad_ajil_zar_view.map(row => {
        //                 return {
        //                     id: row.id,
        //                     aimagname: row.aimagname,
        //                     ajiliin_torol: row.ajiliin_torol,
        //                     ner_mnbaiguullaga: row.ner_mnbrigad,
        //                     d_ognoo: row.d_ognoo,
        //                     logo: row.logo,
        //                     e_ognoo: row.e_ognoo,
        //                     tsagiin_torol: row.tsagiin_torol,
        //                 }
        //             })];
        //             // console.log("this.ajiliin_zar");
        //             // console.log(this.ajiliin_zar);
        //         })
        //         this.loading = false;
        //     })
        // },
        getGet_news_category() {
            this.loading = true;
            this.$apollo.query({query: GET_MEDEE_ANGILAL}).then(({data}) => {
                this.news_categorys = data.ds_medee_angilal_view;
                setTimeout(() => {
                    this.loading = false;
                }, 1000)
            })
        },
        callNewsData(cat_id) {
            this.currentCat = cat_id;
            this.activeItem = cat_id;
            this.loading = true;
            if (this.currentCat) {
                this.$apollo.query({
                    query: GET_NEWS_BY_CATEGORY_ID,
                    variables: {cat_id: this.currentCat.toString()}
                }).then(({data}) => {
                    this.news_datas = data.ds_medee_view;
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000)
                });
            } else {
                this.activeItem = 1;
                this.$apollo.query({query: GET_NEWS_BY_CATEGORY_ID, variables: {cat_id: this.activeItem.toString()}}).then(({data}) => {
                    this.news_datas = data.ds_medee_view;
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000)
                });
            }
        },
        getBanner() {
          this.loading = true;
            this.$apollo.query({query: GET_BANNER_VIEW}).then(({data}) => {
                this.banner = data.ds_banner_view;
                this.banner1 = data.ds_banner_view[0];
                this.banner2 = data.ds_banner_view[1];
                this.banner3 = data.ds_banner_view[2];
                this.banner4 = data.ds_banner_view[3];
                this.banner5 = data.ds_banner_view[4];
              this.loading = false;
            })
        },
        getContacVideo() {
            this.loading = true;
            this.$apollo.query({query: GET_CONTACT_US}).then(({data}) => {
                this.videoList = data.ds_holboo_barih_view[0];
              this.loading = false;
            })
        },
    },
    data() {
        return {
            getVideo: getIdFromURL,
            loading: true,
            activeItem: 1,
            ds_baiguullaga: [],
            ds_brigad_view: [],
            IMAGE_URL: IMAGE_URL,
            //videoId: 'T4CTikfHhMw',
            videoId: '',
            news_categorys: [],
            news_datas: [],
            ajiliin_bairnii_zar: [],
            ajiliin_zar: [],
            banner: [],
            baiguullaga_ajiliin_bairnii_zar: [],
            ds_brigad_ajilin_bair_zar_view: [],
            ds_ajil_bair_zar_view: [],
            baiguullaga_ds_ajiliin_zar_view: [],
            ds_brigad_ajil_zar_view: [],
            videoList: {},
            banner1: {},
            banner2: {},
            banner3: {},
            banner4: {},
            banner5: {},
            medee_medeelel_slide: {
                slidesPerView: 4,
                spaceBetween: 15,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    414: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    }
                }
            },
            video_swiper: {
                slidesPerView: 4,
                spaceBetween: 15,
                navigation: {
                    nextEl: '.swiper-button-next-1',
                    prevEl: '.swiper-button-prev-1'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    414: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    }
                }
            },
            value3: 0,
            value2: 0,
            value1: 0,
            tab: 'Цаг үеийн',
            setting: {
                autoplay: false,
                autoplaySpeed: 3000,
            }
        }

    },
}
</script>

<style scoped>

</style>

