import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./sass/app.scss";
import iView from 'iview';
import locale from 'iview/src/locale/lang/mn-MN';
import BootstrapVue from 'bootstrap-vue';
import VueApollo from 'vue-apollo'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import vuexI18n from "vuex-i18n";
import VueYouTubeEmbed from 'vue-youtube-embed'
import axios from 'axios';
import lodash from 'lodash';
import en from "./locales/en";
import mn from "./locales/mn";
import Swiper, { Navigation, Pagination } from 'swiper';
import VueCkeditor from 'vue-ckeditor2';
import Multiselect from 'vue-multiselect'

const notifList = () => import(/* webpackChunkName: "notify-list" */'@lambda-platform/notify/src/views/notifList')
const widget = () => import(/* webpackChunkName: "notify-widget" */'@lambda-platform/notify/src/views/widget')

import {getDate} from "./utils/date";
import {getNumber} from "./utils/number";
import VueSocialSharing from 'vue-social-sharing'

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);
Vue.use(vuexI18n.plugin, store);
Vue.component('multiselect', Multiselect)
Vue.use(VueCkeditor);
Vue.i18n.add("en", en);
Vue.i18n.add("mn", mn);
Vue.use(VueYouTubeEmbed);
import vmodal from 'vue-js-modal'
Vue.i18n.set('mn');
Vue.use(VueAwesomeSwiper, /* { default global options } */);
Vue.config.productionTip = false;
Vue.use(iView, {locale});
Vue.use(BootstrapVue);
Vue.use(VueApollo);
Vue.component("notif-list", notifList);
Vue.component("notif-widget", widget);
Vue.use(VueSocialSharing);

axios.defaults.baseURL = 'https://job-mnca.mn';
window.axios = axios;
window._ = lodash;
window.Vue = Vue;
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;
Vue.prototype.$static_words = undefined;

import {apolloClient} from "./vue-apollo.js"


const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.use(vmodal, {componentName: "paper-modal"});
Vue.filter("date", (value) => {
  return getDate(value);
});

Vue.filter("number", (value) => {
  return getNumber(value);
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from)=>{
  // if(to.path != '/' && from.path != '/'){
  //   window.webStart();
  // }
  if(window.initted_from_vue){
    window.scrollTo(0, 0);
  } else {
    window.initted_from_vue = true
  }
});

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
